import React from 'react'
import Graph from './Graph';
import Heatmap from './Heatmap/Heatmap'

const UsageHeatmap = ({ labels, dataset }) => (
    <Graph title="Gebruik per niveau en leerjaar" description="In gebruik zie je welke leerlingen gebruik hebben gemaakt van de app in de laatste 30 dagen.">
        <Heatmap labels={labels} dataset={dataset} />
    </Graph>
)

export default UsageHeatmap