import Teacher from './endpoints';
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import find from 'lodash/find'
import Papa from 'papaparse'

export const TEACHER_REQUEST = "TEACHER_REQUEST";
const teacherRequest = () => ({
    type: TEACHER_REQUEST
});
export const TEACHER_REQUEST_SUCCESS = "TEACHER_REQUEST_SUCCESS";
const teachersSuccess = teachers => ({
    type: TEACHER_REQUEST_SUCCESS,
    teachers
});
export const TEACHER_REQUEST_FAILURE = "TEACHER_REQUEST_FAILURE";
const teachersFailure = error => ({
    type: TEACHER_REQUEST_FAILURE,
    error
});

export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const toggleDialog = () => ({
    type: TOGGLE_DIALOG
})

// http call
export const fetchTeachers = () => (dispatch, getState, { http }) => {
    dispatch(teacherRequest());
    return Teacher.find()
      .then(teachers => dispatch(teachersSuccess(teachers)))
      .catch(error => dispatch(teachersFailure(error)));
};

export const ROLE_REQUEST = "ROLE_REQUEST";
const rolesRequest = () => ({
  type: ROLE_REQUEST
});

export const ROLE_REQUEST_SUCCESS = "ROLE_REQUEST_SUCCESS";
const rolesRequestSuccess = roles => ({
  type: ROLE_REQUEST_SUCCESS,
  roles
})

export const ROLE_REQUEST_FAILURE = "ROLE_REQUEST_FAILURE";
const rolesRequestFailure = error => ({
  type: ROLE_REQUEST_FAILURE,
  error
})

export const fetchRoles = () => (dispatch, getState, { http }) => {
  dispatch(rolesRequest());
  http.get('/role')
    .then(res => res.data)
    // filter empty roles
    .then(roles => roles.filter(role => role.name))
    // filter admin role
    .then(roles => roles.filter(role => role.id !== 1))
    .then(roles => dispatch(rolesRequestSuccess(roles)))
    .catch(error => dispatch(rolesRequestFailure(error)))
};

export const SET_TEACHER = "SET_TEACHER";
export const setTeacher = teacher => ({
  type: SET_TEACHER,
  teacher
})

export const SAVE_TEACHER_REQUEST = "SAVE_TEACHER_REQUEST";
const saveTeacherRequest = () => ({
  type: SAVE_TEACHER_REQUEST
})

export const SAVE_TEACHER_REQUEST_SUCCESS = "SAVE_TEACHER_REQUEST_SUCCESS";
const saveTeacherRequestSucces = teacher => ({
  type: SAVE_TEACHER_REQUEST_SUCCESS,
  teacher
})

export const SAVE_TEACHER_REQUEST_FAILURE = "SAVE_TEACHER_REQUEST_FAILURE";
const saveTeacherRequestFailure = error => ({
  type: SAVE_TEACHER_REQUEST_FAILURE,
  error
})

export const saveTeacher = () => (dispatch, getState) => {
  const teacherToSave = getState().Teacher.teacher;
  dispatch(saveTeacherRequest());
  const request = teacherToSave.id 
    ? Teacher.update(teacherToSave.id, teacherToSave)
    : Teacher.create(teacherToSave)

  request
    .then(teacher => dispatch(saveTeacherRequestSucces(teacher)))
    .catch(error => dispatch(saveTeacherRequestFailure(error)))
}

export const SET_QUERY = "SET_QUERY";
export const setQuery = query => ({
  type: SET_QUERY,
  query
})

export const REMOVE_TEACHER_REQUEST = "REMOVE_TEACHER_REQUEST";
const removeTeacherRequest = () => ({
  type: REMOVE_TEACHER_REQUEST
})

export const REMOVE_TEACHER_REQUEST_SUCCESS = "REMOVE_TEACHER_REQUEST_SUCCESS";
const removeTeacherRequestSuccess = teacher => ({
  type: REMOVE_TEACHER_REQUEST_SUCCESS,
  teacher
})

export const REMOVE_TEACHER_REQUEST_FAILURE = "REMOVE_TEACHER_REQUEST_FAILURE";
const removeTeacherRequestFailure = error => ({
  type: REMOVE_TEACHER_REQUEST_FAILURE,
  error
})

export const removeTeacher = teacher => (dispatch, getState, { http }) => {
  dispatch(removeTeacherRequest())
  return http.delete(`/user/${teacher.id}`)
    .then(res => dispatch(removeTeacherRequestSuccess(teacher)))
    .catch(error => dispatch(removeTeacherRequestFailure(error)))
}

export const STAGE_FOR_IMPORT = "STAGE_FOR_IMPORT"
export const onBulkImport = (err, file) => (dispatch) => {
  Papa.parse(file.file, {
    header: true,
    delimiter: `;`,
    complete: (results) => {
      dispatch({
        type: STAGE_FOR_IMPORT,
        payload: results.data
      })
    },
  })   
}

export const startImportWithMap = (mappedFields, history) => (dispatch, getState) => {
  const { stagedForImport, teachers } = getState().Teacher
  // reduce stage to import with mappedFields
  const csvToTeacher = uniqBy(
    reduce(stagedForImport, (acc, item) => {
      const teacher = {}
      map(mappedFields, (value, key) => {
        teacher[key] = item[value]
      })
      acc.push(teacher)
      return acc
    }, []),
    'email'
  )
  
  // filter by email with existing teachers
  const teachersToImport = csvToTeacher.filter(teacher => {
    if (! find(teachers, { email: teacher.email }) ) {
      return teacher
    }
    return false
  })

  // prepare Teacher.create promises
  const teacherCreateHttp = teachersToImport.map(teacher => Teacher.create(teacher))
  dispatch(startImport())
  Promise.all(teacherCreateHttp)
    .then(teachers => {
      teachers.map(teacher => dispatch(addTeacher(teacher)))
      dispatch(finishImport())
      history.push(`/teachers`)
    })
    .catch(err => dispatch(errorImport(err)))
}

export const ADD_TEACHER = `ADD_TEACHER`
export const addTeacher = teacher => ({
  type: ADD_TEACHER,
  teacher
})

export const START_IMPORT = `START_IMPORT`
export const startImport = () => ({ type: START_IMPORT })
export const ERROR_IMPORT = `ERROR_IMPORT`
export const errorImport = (error) => ({ type: ERROR_IMPORT, error })
export const FINISH_IMPORT = `FINISH_IMPORT`
export const finishImport = () => ({ type: FINISH_IMPORT })