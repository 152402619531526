import React, { Component } from 'react';
import ErrorPage from './index';
import { Button } from '@blueprintjs/core';

class NotFound extends Component {
  render() {
    return (
      <ErrorPage>
          <h1>404</h1>
          <p className="error-description">Deze pagina bestaat niet.</p>
          <Button className="pt-button" type="submit" onClick={this.props.history.goBack}>Terug</Button>
      </ErrorPage>
    );
  }
}

export default NotFound;
