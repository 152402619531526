import React, { Component } from 'react';
import { Popover, Position, Menu, MenuItem, Button, Intent, Card, Dialog } from "@blueprintjs/core";
import Avatar from '../Avatar';
import StudentClassBadge from './StudentClassBadge';

export default class TutorCardItem extends Component {

  constructor(props){
    super(props);
    this.state = { isOpen: false };
    this.toggleWithdrawPermission = this.toggleWithdrawPermission.bind(this);
  }

  toggleWithdrawPermission(){
    this.setState({ isOpen: !this.state.isOpen });
  }

  revokeTutorship() {
    const { tutor, course } = this.state;
    this.props.revokeTutorship(tutor, course);
    this.toggleWithdrawPermission()
  }

  render() {
    return (
      <div className="card-wrapper">
        <Card>
          <div className="card-content">
            <div className="card-title">
              <Avatar src={this.props.tutor.user.avatarUrl} placeholder={this.props.tutor.user.name} />
              <div className="card-text">
                <p className="body-2">
                  {this.props.tutor.user.name}
                </p>
                <span className="caption"><StudentClassBadge student={this.props.tutor.student} /></span>
              </div>
            </div>
            <div className="courses">
                <p className="body-2">Tutorvakken</p>
                {this.props.tutor.tutorship.map(course => (
                  <div key={course.id} className="course">
                    <p className="body-1">{course.name}</p>
                    <Popover
                      className="col-xs list-item-action"
                      position={Position.LEFT_TOP}
                      content={
                        <Menu>
                          <MenuItem
                            onClick={() => {
                              this.setState({
                                tutor: this.props.tutor.id,
                                course: course.id
                              }, () => this.toggleWithdrawPermission())
                            }}
                            text="Toestemming intrekken"
                          />
                        </Menu>
                      }
                      target={<Button id={"popOverClick"} iconName="more" />}
                    />
                  </div>
                ))}
            </div>
          </div>
        </Card>
        <Dialog
            iconName="alert"
            isOpen={this.state.isOpen}
            onClose={this.toggleWithdrawPermission}
            title="Toestemming intrekken"
        >
            <div className="pt-dialog-footer">
                <div className="pt-dialog-footer-actions">
                    <Button text="Bevestigen" onClick={() => this.revokeTutorship()}/>
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={this.toggleWithdrawPermission}
                        text="Annuleren"
                    />
                </div>
            </div>
        </Dialog>
      </div>
    )
  }
}
