import moment from "moment";
import get from 'lodash/get'
import { loggedInSchool } from '../AuthenticatedUser/selectors'

export const CREATE_SCHOOL_CODE_REQUEST = "CREATE_SCHOOL_CODE_REQUEST";
const createSchoolCodeRequest = () => ({
  type: CREATE_SCHOOL_CODE_REQUEST
});

export const CREATE_SCHOOL_CODE_REQUEST_SUCCESS =
  "CREATE_SCHOOL_CODE_REQUEST_SUCCESS";
const createSchoolCodeRequestSuccess = code => ({
  type: CREATE_SCHOOL_CODE_REQUEST_SUCCESS,
  code: code
});

export const CREATE_SCHOOL_CODE_REQUEST_ERROR =
  "CREATE_SCHOOL_CODE_REQUEST_ERROR";
const createSchoolCodeRequestError = error => ({
  type: CREATE_SCHOOL_CODE_REQUEST_ERROR,
  error
});

const isCodeExpired = code => moment().diff(moment(code.expiresAt)) > 0;

const shouldCreateCode = state => {
  if (state.Code.code && get(state, 'Code.code.school.id') !== loggedInSchool(state) ) {
    return true
  }
  if (!state.Code.code.code) {
    return true;
  }
  if (isCodeExpired(state.Code.code)) {
    return true;
  }
  return false;
};

export const createSchoolCode = school => (dispatch, getState, { io }) => {
  if (shouldCreateCode(getState())) {
    dispatch(createSchoolCodeRequest());
    school = loggedInSchool(getState()).id

    io.socket.post(`/v1/code`, { school }, (code, jwr) => {
      if (jwr.statusCode === 201) {
        dispatch(createSchoolCodeRequestSuccess(code))
        dispatch(subscribeToSchoolCode())
      } else {
        dispatch(createSchoolCodeRequestError())
      }
    })
  }
};

const subscribeToSchoolCode = () => (dispatch, getState, { io }) => {
  io.socket.on(`signup`, (user) => {
    dispatch(newSignup(user))
  })
}

export const NEW_SIGNUP = 'NEW_SIGNUP'
const newSignup = (user) => ({
  type: NEW_SIGNUP,
  user
})