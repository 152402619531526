import React from "react";
import {
  Tooltip,
  Position,
  NonIdealState,
  Spinner,
  Button,
  Colors,
  AnchorButton,
  Intent
} from "@blueprintjs/core";
import { connect } from "react-redux";
import moment from "moment";
import { userById } from "../../store/User/selectors";
import { fetchUser, deleteUser, restoreUser } from "../../store/User/actions";
import { openSidenav } from "../../store/App/actions";
import Avatar from "../../components/Avatar";
import http from "../../store/http";

const Navbar = ({ user, history, onDelete, onRestore }) => (
  <nav className="pt-navbar row middle-xs">
    <div className="pt-navbar-group col-xs">
      <button
        className="pt-button pt-icon-arrow-left pt-large show-up-to-desktop"
        onClick={() => history.goBack()}
      />
      <h3 className="pt-navbar-heading">{user ? user.name : "Gebruiker"}</h3>
    </div>
    <div className="pt-navbar-group pt-align-right pt-navbar--button-group">
      {user && !user.deletedAt && (
        <Tooltip content="Verwijderen" position={Position.BOTTOM}>
          <button
            className="pt-button pt-icon-trash pt-large"
            onClick={() => onDelete(user)}
          />
        </Tooltip>
      )}
      {user && user.deletedAt && (
        <Tooltip content="Activeren" position={Position.BOTTOM}>
          <button
            className="pt-button pt-icon-undo pt-large"
            onClick={() => onRestore(user)}
          />
        </Tooltip>
      )}
    </div>
  </nav>
);

class UsersEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreatingLoginLink: false,
      loginUrl: null
    };
  }

  componentDidMount() {
    const { user, fetchUser } = this.props;
    if (!user) {
      fetchUser();
    }
  }

  createLoginLink() {
    const { user } = this.props;
    if (!user) {
      return;
    }

    this.setState({ isCreatingLoginLink: true });

    http
      .post(`/admin/loginAsUser`, {
        sub: user.id
      })
      .then(res => res.data)
      .then(({ accessToken, refreshToken }) => {
        const url = `https://app.getsavvy.nl/#/login/login-as-user?accessToken=${accessToken}&refreshToken=${refreshToken}`;
        this.setState({ loginUrl: url });
      })
      .catch(console.error)
      .finally(() => this.setState({ isCreatingLoginLink: false }));
  }

  render() {
    const props = this.props;
    const { user } = props;
    const { isCreatingLoginLink, loginUrl } = this.state;

    if (!user) {
      return (
        <div>
          <Navbar {...props} />
          <NonIdealState visual={<Spinner />} />
        </div>
      );
    }

    return (
      <div>
        <Navbar {...props} />
        <div className="list">
          <div className="list-item row middle-xs">
            <Avatar src={user.avatarUrl} placeholder={user.name} />
            <div className="two-line col-xs">
              <p className="body-2">{user.name}</p>
              <p className="body-1">
                <a href={`mailto: ${user.email}`}>{user.email}</a>
              </p>
            </div>
          </div>
          <div className="list-item">
            <p className="body-1">
              Geregistreerd op:{" "}
              {moment(user.createdAt).format("dd MMM YYYY [om] HH:mm [uur]")}
            </p>
          </div>
          {user.lastSeen && (
            <div className="list-item">
              <p className="body-1">
                Laatst gezien op:{" "}
                {moment(user.lastSeen).format("dd MMM YYYY [om] HH:mm [uur]")}
              </p>
            </div>
          )}
          {user.deletedAt && (
            <div className="list-item">
              <p className="body-1" style={{ color: Colors.RED3 }}>
                Verwijderd op:{" "}
                {moment(user.deletedAt).format("dd MMM YYYY [om] HH:mm [uur]")}
              </p>
            </div>
          )}
          {user.parentName && user.parentEmail && (
            <div className="list-item">
              <p className="body-1">
                Ouders: {user.parentName} ({user.parentEmail})
              </p>
            </div>
          )}
          <div className="list-item">
            <Button
              onClick={() => this.createLoginLink()}
              loading={isCreatingLoginLink}
            >
              Maak login link
            </Button>
            {loginUrl && (
              <AnchorButton intent={Intent.SUCCESS} href={loginUrl}>
                Login
              </AnchorButton>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: userById(state, ownProps.match.params.id)
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  openSidenav: () => dispatch(openSidenav()),
  fetchUser: () => dispatch(fetchUser(ownProps.match.params.id)),
  onDelete: user => {
    const shouldDelete = window.confirm(
      "Weet je zeker dat je deze gebruiker wilt verwijderen?"
    );
    if (!shouldDelete) return;
    dispatch(deleteUser(user));
  },
  onRestore: user => dispatch(restoreUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersEditPage);
