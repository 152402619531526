import React, { Component } from 'react';
import AuthenticateForm from './AuthenticateForm';
import { Button, Callout, Intent } from '@blueprintjs/core';

class AuthenticateEmailForm extends Component {
  render() {
    return (
      <AuthenticateForm onSubmit={this.props.onSubmit}>
          {!this.props.error ? null : (<Callout title="Oeps" intent={Intent.DANGER}>{this.props.error.message}</Callout>)}
          <h4>Savvy docentenportaal</h4>
          <p className="authentication-description">Login met je school e-mailadres</p>
          <input type="email" className="pt-input" placeholder="E-mailadres" onChange={this.props.onChange} required autoFocus/>
          <Button className="pt-intent-primary" loading={this.props.loading} type="submit">Inloggen</Button>
      </AuthenticateForm>
    );
  }
}

export default AuthenticateEmailForm;
