import {
  includes, 
  toLower,
  last,
  sumBy,
  sum,
  flatMap,
  flattenDeep,
  map,
  reduce,
  groupBy,
  orderBy
} from "lodash";
import _ from 'lodash'
import moment from 'moment';
import { loggedInSchool } from "../AuthenticatedUser/selectors";

export const getVisibleSchools = (schools, query) => {
  if (!query) return schools;
  return schools.filter(school =>
    includes(toLower(school.name), toLower(query))
  );
};

export const getSignupsThisWeek = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school];
  return stats ? last(stats.schoolStats.totalSignUps) : ''
}

export const getTotalAppointmentsThisWeek = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school];
  return stats ? last(stats.reqStats.requestsPerWeek) : ''
}

export const getLabels = state => {
  const school = loggedInSchool(state)
  const statsForSchool = state.schools.stats[school]
  if (!statsForSchool) {
    return []
  }
  const stats = statsForSchool.schoolStats
  const start = moment(stats.start)
  const end = moment(stats.end)
  const labels = []

  while (end.isAfter(start)) {
    labels.push('Week ' + start.week())
    start.add(1, 'week')
  }

  return labels
}

export const getTotalSignupsData = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  return stats ? stats.schoolStats.totalSignUps : []
}

export const getTotalAppointmentsData = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  return stats ? stats.reqStats.requestsPerWeek : []
}

export const totalQuestions = state => {
  const school = loggedInSchool(state)
  try {
    const stats = state.schools.stats[school]
    const { questionsPerWeek } = stats.schoolStats
    return sumBy(flatMap(questionsPerWeek), 'questions')
  } catch(err) {
    return 0
  }
}

export const totalTutoring = state => {
  const school = loggedInSchool(state)
  try {
    const stats = state.schools.stats[school]
    const { tutoringPerWeek } = stats.schoolStats
    return sumBy(flatMap(tutoringPerWeek), 'tutoring')
  } catch(err) {
    return 0
  }
}

export const totalSignups = state => {
  const school = loggedInSchool(state)
  try {
    const stats = state.schools.stats[school]
    const { totalSignUps } = stats.schoolStats
    return reduce(totalSignUps, (sum, week) => {
      return sum + sumBy(flattenDeep(map(week)), 'signups')
    }, 0)
  } catch(err) {
    return 0
  }
}

export const activityDataset = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return []
  }

  const { tutoringPerWeek, questionsPerWeek, signupsPerWeek } = stats.schoolStats
  const tutoringData = map(tutoringPerWeek, weekData => sumBy(weekData, 'tutoring'))
  const questionData = map(questionsPerWeek, weekData => sumBy(weekData, 'questions'))
  const signupData = map(signupsPerWeek, weekData => {
    return sum(flatMap(weekData, i => flatMap(i)))
  })
  const totalData = map(tutoringData, (item, index) => {
    return item + questionData[index] + signupData[index]
  })
  
  return [{
    label: 'Hulpvragen',
    data: questionData,
  },
  {
    label: 'Bijlesafspraken',
    data: tutoringData,
  },
  {
    label: 'Totale activiteit',
    data: totalData,
  },
  {
    label: 'Aanmeldingen',
    data: signupData
  }]
}

export const activeUsersLastMonth = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return 0
  }

  const { activeUsersLastMonth } = stats.schoolStats

  return activeUsersLastMonth
}

const flattenByCourse = (stats) => groupBy(
  flattenDeep(
    map(
      stats,
      level => map(level)
    )
  ),
  'course'
)

export const talentsDataset = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return []
  }
  
  const { talents } = stats.schoolStats
  const statsPerCourse = flattenByCourse(talents)
  const totalsPerCourse = reduce(statsPerCourse, (acc, course) => {
    acc.push({
      course: course[0].course,
      talents: sumBy(course, 'talents')
    })

    return acc
  }, [])

  return orderBy(totalsPerCourse, ['talents'], ['desc'])
}

export const weaknessesDataset = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return []
  }
  
  const { weaknesses } = stats.schoolStats
  const statsPerCourse = flattenByCourse(weaknesses)
  const totalsPerCourse = reduce(statsPerCourse, (acc, course) => {
    acc.push({
      course: course[0].course,
      weaknesses: sumBy(course, 'weaknesses')
    })

    return acc
  }, [])

  return orderBy(totalsPerCourse, ['weaknesses'], ['desc'])
}

export const tutorsDataset = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return []
  }
  
  const { availableTutors } = stats.schoolStats
  const statsPerCourse = flattenByCourse(availableTutors)
  const totalsPerCourse = reduce(statsPerCourse, (acc, course) => {
    acc.push({
      course: course[0].course,
      tutors: sumBy(course, 'tutors')
    })

    return acc
  }, [])

  return orderBy(totalsPerCourse, ['tutors'], ['desc']) 
}

export const signupsHeatmap = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return {
      labels: [],
      dataset: []
    }
  }
  
  const { totalSignUps } = stats.schoolStats
  const heatmap = reduce(totalSignUps, (acc, years, levelName) => {
    map(years, (year) => {
      if (!acc[levelName]) {
        acc[levelName] = {}
      }

      if (!acc[levelName][year.year]) {
        acc[levelName][year.year] = 0
      }

      acc[levelName][year.year] += year.signups
    })

    return acc
  }, {})

  const labels = _.uniq(_.flatten(_.map(_.flatMap(heatmap), _.keys)))
  const dataset = map(heatmap, (level, levelName) => {
    return {
      label: levelName,
      data: map(level)
    }
  })

  return {
    dataset,
    labels,
  }
}

export const period = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return ''
  }

  const { start, end } = stats.schoolStats
  
  return `${moment(start).year()}-${moment(end).year()}`
}

export const tutoring = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return []
  }
  
  const { tutoring } = stats.schoolStats
  const statsPerCourse = flattenByCourse(tutoring)
  const totalsPerCourse = reduce(statsPerCourse, (acc, course) => {
    acc.push({
      course: course[0].course,
      tutoring: sumBy(course, 'tutoring')
    })

    return acc
  }, [])

  return orderBy(totalsPerCourse, ['tutoring'], ['desc'])  
}

export const questions = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return []
  }
  
  const { questions } = stats.schoolStats
  const statsPerCourse = flattenByCourse(questions)
  const totalsPerCourse = reduce(statsPerCourse, (acc, course) => {
    acc.push({
      course: course[0].course,
      questions: sumBy(course, 'questions')
    })

    return acc
  }, [])

  return orderBy(totalsPerCourse, ['questions'], ['desc'])  
}

export const questionsHeatmap = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return {
      labels: [],
      dataset: []
    }
  }
  
  const { questions } = stats.schoolStats
  const heatmap = reduce(questions, (acc, level, levelName) => {
    map(level, (courses, year) => {
      if (!acc[levelName]) {
        acc[levelName] = {}
      }

      if (!acc[levelName][year]) {
        acc[levelName][year] = 0
      }

      acc[levelName][year] += sumBy(courses, 'questions')
    })

    return acc
  }, {})

  const labels = _.uniq(_.flatten(_.map(_.flatMap(heatmap), _.keys)))
  const dataset = map(heatmap, (level, levelName) => {
    return {
      label: levelName,
      data: map(level)
    }
  })

  return {
    dataset,
    labels,
  }
}

export const paymentRequestsDataset = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return []
  }

  const { paymentRequests } = stats.schoolStats
  const open = map(paymentRequests, 'open')
  const paid = map(paymentRequests, 'paid')
  const revenue = map(paymentRequests, 'revenue')
  
  return [{
    label: 'Open',
    data: open,
  },
  {
    label: 'Betaald',
    data: paid,
  },
  {
    label: 'Omzet',
    data: revenue,
  }]
}

export const usageHeatmap = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return {
      labels: [],
      dataset: []
    }
  }
  
  const { activity } = stats.schoolStats
  const heatmap = reduce(activity, (acc, years, levelName) => {
    map(years, (year) => {
      if (!acc[levelName]) {
        acc[levelName] = {}
      }

      if (!acc[levelName][year.year]) {
        acc[levelName][year.year] = 0
      }

      acc[levelName][year.year] += year.activeUsers
    })

    return acc
  }, {})

  const labels = _.uniq(_.flatten(_.map(_.flatMap(heatmap), _.keys)))
  const dataset = map(heatmap, (level, levelName) => {
    return {
      label: levelName,
      data: map(level)
    }
  })

  return {
    dataset,
    labels,
  }
}

export const questionsAndTutoringPerDay = state => {
  const school = loggedInSchool(state)
  const stats = state.schools.stats[school]
  if (!stats) {
    return []
  }

  const { questionsAndTutoringPerDay } = stats.schoolStats
  const labels = map(questionsAndTutoringPerDay, 'day')
  const dataset = reduce(questionsAndTutoringPerDay, (acc, day) => {
    acc[0].data.push(day.questions)
    acc[1].data.push(day.tutoring)
    acc[2].data.push(day.total)

    return acc
  }, [{
    label: 'Hulpvragen',
    data: []
  }, {
    label: 'Bijlessen',
    data: []
  }, {
    label: 'Totaal',
    data: []
  }])

  return {
    labels,
    dataset
  }
}

export const isLoading = state => state.schools.isLoading

export const getSchoolById = (state, id) => {
  return state.schools.schools.filter(school => {
    if (school.id === parseInt(id, 10)) {
      return school
    }
    return false
  }).pop()
}