import React, { Component } from "react";
import { connect } from "react-redux";
import SchoolCode from "../components/SchoolCode";
import { NonIdealState, Spinner, Callout, Intent } from "@blueprintjs/core";
import { createSchoolCode } from "../store/Code/actions";
import { openSidenav } from "../store/App/actions";

class SchoolCodeContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(createSchoolCode());
  }

  render() {
    return (
      <div className="schoolcode-page">
        <nav className="pt-navbar row middle-xs">
          <div className="pt-navbar-group col-xs">
            <button  className="pt-button pt-icon-menu pt-large show-up-to-desktop" onClick={() => this.props.dispatch(openSidenav())}></button>
            <h3 className="pt-navbar-heading">Schoolcode</h3>
          </div>
        </nav>
        {this.props.error && !this.props.isFetching && !this.props.code ? (
          <Callout intent={Intent.DANGER}>{this.props.error.message}</Callout>
        ) : (
          <SchoolCode {...this.props} />
        )}
        {this.props.isFetching ? (
          <NonIdealState
            description="Schoolcode wordt aangemaakt..."
            visual={<Spinner />}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { Code } = state;
  return Code;
};

export default connect(mapStateToProps)(SchoolCodeContainer);
