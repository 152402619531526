import React, { Component } from "react";
import Header from '../Header/Header';
import {
  Checkbox,
  Button,
  Icon
} from '@blueprintjs/core';

export default class StatsFilter extends Component {
  static defaultProps = {
    levels: [],
    years: [],
    schoolYears: [{
      start: 0,
      end: 1,
      name: `2017-2018`
    }, {
      start: 0,
      end: 1,
      name: `2018-2019`
    }],
    showFilters: false
  }

  constructor(props) {
    super(props)
    this.onKeyPress = this.onKeyPress.bind(this)
  }

  componentDidMount(){
    document.addEventListener(`keydown`, this.onKeyPress, false);
  }

  componentWillUnmount(){
    document.removeEventListener(`keydown`, this.onKeyPress, false);
  }

  onKeyPress(e) {
    if(e.keyCode === 27 && this.props.showFilters === true) {
      this.props.toggleStatsFilter()
    }
  }

  onSchoolYearChange(e) {
    const [start, end] = e.target.value.split(`:`)
    this.props.setFilter({
      start, end
    })
  }

  onLevelChange(e, level) {
    this.props.setFilter({
      level
    })
  }

  onYearChange(e, year) {
    this.props.setFilter({
      year
    })
  }

  render() {
    return (
      <div className={`filter-menu ${this.props.showFilters ? `open` : `close`}`}>
        <div className="filter-menu-header">
          <Header title="Filters" />
          <Icon onClick={() => this.props.toggleStatsFilter()} iconName="cross" />
        </div>
        <div className="filter-menu-content">
          <div className="filter-menu-list">
            <p className="body-2">Schooljaar</p>
            <div className="pt-select pt-fill">
              <select onChange={e => this.onSchoolYearChange(e)}>
                {this.props.schoolYears.map(year => 
                  <option key={year.name} value={`${year.start}:${year.end}`} selected={year.selected}>{year.name}</option>
                )}
              </select>
            </div>
          </div>
          <div className="divider"></div>
          <div className="filter-menu-list">
            <p className="body-2">Leerniveau's</p>
            {this.props.levels.map(level => 
              <Checkbox key={level.id} className="pt-large" checked={level.checked} label={level.name} onChange={(e) => this.onLevelChange(e, level)} />
            )}
          </div>
          <div className="divider"></div>
          <div className="filter-menu-list">
            <p className="body-2">Leerjaren</p>
            {this.props.years.map(year => 
              <Checkbox key={year.id} className="pt-large" checked={year.checked} label={year.name} onChange={(e) => this.onYearChange(e, year)} />
            )}
          </div>
          <div className="divider"></div>
          <div className="filter-menu-list">
            <Button text="Filters wissen" className="pt-fill filter-menu-reset" onClick={() => this.props.resetFilter()}/>
          </div>
        </div>
      </div>
    )
  }
}