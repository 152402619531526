import Level from './endpoints';
import find from 'lodash/find';
import { loggedInSchool } from '../AuthenticatedUser/selectors';

export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const toggleDialog = () => ({
    type: TOGGLE_DIALOG
})

export const LEVEL_REQUEST = "LEVEL_REQUEST";
const levelRequest = () => ({
    type: LEVEL_REQUEST
})

export const LEVEL_REQUEST_SUCCESS = "LEVEL_REQUEST_SUCCESS";
const levelRequestSuccess = levels => ({
    type: LEVEL_REQUEST_SUCCESS,
    levels
})

export const LEVEL_REQUEST_FAILURE = "LEVEL_REQUEST_FAILURE";
const levelRequestFailure = error => ({
    type: LEVEL_REQUEST_FAILURE,
    error
})

export const fetchLevels = () => (dispatch, getState) => {
    dispatch(levelRequest());
    Level.find()
        .then(levels => dispatch(levelRequestSuccess(levels)))
        .catch(error => dispatch(levelRequestFailure(error)))
}

export const SET_LEVEL = "SET_LEVEL";
export const setLevel = level => ({
    type: SET_LEVEL,
    level
})

export const SAVE_LEVEL_REQUEST = "SAVE_LEVEL_REQUEST";
const saveLevelRequest = () => ({
    type: SAVE_LEVEL_REQUEST
})

export const SAVE_LEVEL_REQUEST_SUCCESS = "SAVE_LEVEL_REQUEST_SUCCESS";
const saveLevelRequestSuccess = level => ({
    type: SAVE_LEVEL_REQUEST_SUCCESS,
    level
})

export const SAVE_LEVEL_REQUEST_FAILURE = "SAVE_LEVEL_REQUEST_FAILURE";
const saveLevelRequestFailure = error => ({
    type: SAVE_LEVEL_REQUEST_FAILURE,
    error
})

export const saveLevel = (level) => (dispatch, getState) => {
    dispatch(saveLevelRequest())
    const request = level.id
        ? Level.update(level.id, level)
        : Level.create(level).then(level => Level.findOne(level.id))
    return request
        .then(_level => dispatch(saveLevelRequestSuccess(_level)))
        .catch(error => dispatch(saveLevelRequestFailure(error)))
}

export const saveForm = form => (dispatch, getState) => {
    const { level, years } = form

    level.school = level.school || loggedInSchool(getState())
    level.difficulty = parseInt(level.difficulty, 10)

    level.years = years
        .reduce((acc, year) => {
            // if year is checked, check if exists and if missing add year
            const hasYear = !!find(acc, { year: year.year })
            if (year.checked === true && !hasYear) {
                // level does not exist yet so we add it.
                acc.push({
                    name: `Leerjaar ${year.year}`,
                    longName: `Leerjaar ${year.year} ${level.name}`,
                    year: year.year,
                    level: level.id,
                    school: level.school.id,
                })
            }
            // if year is unchecked, check if exists, and if existst remove
            if (year.checked === false && hasYear) {
                acc = acc.filter(_year => year.year !== _year.year)
            }

            return acc
        }, level.years)

    dispatch(saveLevelRequest())
    const request = level.id
        ? Level.update(level.id, level)
        : Level.create(level).then(level => Level.findOne(level.id))

    return request
        .then(_level => dispatch(saveLevelRequestSuccess(_level)))
        .catch(error => dispatch(saveLevelRequestFailure(error)))
}

export const TOGGLE_REMOVE_LEVEL_ALERT = "TOGGLE_REMOVE_LEVEL_ALERT";
export const toggleRemoveLevelAlert = () => ({
    type: TOGGLE_REMOVE_LEVEL_ALERT
})

export const DELETE_LEVEL_REQUEST = "DELETE_LEVEL_REQUEST";
const deleteLevelRequest = () => ({
    type: DELETE_LEVEL_REQUEST
})
export const DELETE_LEVEL_REQUEST_SUCCESS = "DELETE_LEVEL_REQUEST_SUCCESS";
const deleteLevelRequestSuccess = level => ({
    type: DELETE_LEVEL_REQUEST_SUCCESS,
    level
})
export const DELETE_LEVEL_REQUEST_FAILURE = "DELETE_LEVEL_REQUEST_FAILURE";
const deleteLevelRequestFailure = error => ({
    type: DELETE_LEVEL_REQUEST_FAILURE,
    error
})

export const deleteLevel = level => (dispatch, getState) => {
    dispatch(deleteLevelRequest());
    return Level.destroy(level.id)
        .then(() => dispatch(deleteLevelRequestSuccess(level)))
        .catch(error => dispatch(deleteLevelRequestFailure(error)))
}

export const SET_QUERY = "SET_QUERY";
export const setQuery = query => ({
    type: SET_QUERY,
    query
})