import React from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import PrivateRoute from './PrivateRoute';

import NavLink from "./../components/Sidenav/NavLink";
import SchoolLink from "./../components/Sidenav/SchoolLink";

import App from "./../components/App";
import StatsContainer from "../containers/StatsContainer";
import AuthenticateContainer from "./../containers/AuthenticateContainer";
import SchoolCodeContainer from "../containers/SchoolCodeContainer";
import SidenavContainer from "../containers/SidenavContainer";
import { logoutAsSchool } from "../store/AuthenticatedUser/actions";
import CoursesContainer from "../containers/CoursesContainer";
import TeacherContainer from "../containers/TeacherContainer";
import TutorsContainer from "../containers/TutorsContainer/index";
import ForgotPasswordContainer from "../containers/ForgotPasswordContainer";
import NotFound from "../components/Error/404";
import Unauthorized from "../components/Error/Unauthorized";
import PaymentsContainer from "../containers/PaymentsContainer";

import LevelListPage from "../pages/Levels/List";
import LevelEditPage from "../pages/Levels/Edit";
import LevelCreatePage from "../pages/Levels/Create";
import SchoolListContainer from "../containers/School/SchoolListContainer";
import SchoolDetailContainer from "../containers/School/SchoolDetailContainer";
import SchoolEditContainer from "../containers/School/SchoolEditContainer";
import SchoolCreateContainer from "../containers/School/SchoolCreateContainer";
import UsersEditPage from "../pages/Users/Edit";

const Routes = props => (
  <div className="row">
    <App>
      <SidenavContainer {...props}>
        {props.isAdmin &&
          props.loggedInAsSchool && (
            <SchoolLink onClick={() => {
              props.logoutAsSchool()
              props.history.push("/schools")
            }}>
              {props.loggedInAsSchool.name}
            </SchoolLink>
          )}
        <NavLink roles={props.roles} hasRole={["admin", "school administrator", "teacher"]} to="/stats">
          Statistieken <span className="pt-tag label orange">beta</span>
        </NavLink>
        <NavLink roles={props.roles} hasRole={["admin"]} to="/schools">
          Scholen
        </NavLink>
        <NavLink
          roles={props.roles}
          hasRole={["admin", "school administrator"]}
          to="/teachers"
        >
          Docenten
        </NavLink>
        <NavLink
          roles={props.roles}
          hasRole={["admin", "school administrator", "teacher"]}
          to="/tutors"
        >
          Bijles
        </NavLink>
        <NavLink
          roles={props.roles}
          hasRole={["admin", "school administrator", "teacher"]}
          to="/courses"
        >
          Vakken
        </NavLink>
        <NavLink
          roles={props.roles}
          hasRole={["admin", "school administrator", "teacher"]}
          to="/levels"
        >
          Leerniveau's
        </NavLink>
        <NavLink
          roles={props.roles}
          hasRole={["admin", "school administrator", "teacher"]}
          to="/schoolcode"
        >
          Schoolcode
        </NavLink>
        <NavLink
          roles={props.roles}
          hasRole={["admin"]}
          to="/payments"
        >
          Uitbetalingen
        </NavLink>
      </SidenavContainer>

      <div className="content col-xs">
        <Switch>
          <PrivateRoute
            path="/stats"
            hasRole={["admin", "teacher"]}
            component={StatsContainer}
          />
          {/* Schools */}
          <PrivateRoute
            path="/schools"
            exact
            hasRole={["admin"]}
            component={SchoolListContainer}
          />
          <PrivateRoute
            path="/schools/create"
            exact
            hasRole={["admin"]}
            component={SchoolCreateContainer}
          />
          <PrivateRoute
            path="/schools/:id"
            hasRole={["admin"]}
            exact
            component={SchoolDetailContainer}
          />
          <PrivateRoute
            path="/schools/:id/edit"
            hasRole={["admin"]}
            exact
            component={SchoolEditContainer}
          />
          {/* Teachers */}
          <PrivateRoute
            path="/teachers"
            hasRole={["admin", "school administrator"]}
            component={TeacherContainer}
          />
          <PrivateRoute
            path="/tutors"
            hasRole={["admin", "school administrator", "teacher"]}
            component={TutorsContainer}
          />
          <PrivateRoute
            path="/schoolcode"
            hasRole={["admin", "school administrator", "teacher"]}
            component={SchoolCodeContainer}
          />
          <PrivateRoute
            path="/payments"
            hasRole={["admin"]}
            component={PaymentsContainer}
          />
          <PrivateRoute
            path="/courses"
            hasRole={["admin", "school administrator", "teacher"]}
            component={CoursesContainer}
          />

          <PrivateRoute path="/levels" exact hasRole={["admin", "school administrator", "teacher"]} component={LevelListPage} />
          <PrivateRoute
            path="/levels/:id/edit"
            exact
            hasRole={["admin", "school administrator", "teacher"]}
            component={LevelEditPage}
          />
          <PrivateRoute
            path="/levels/create"
            exact
            hasRole={["admin", "school administrator", "teacher"]}
            component={LevelCreatePage}
          />

          <PrivateRoute
            path="/users/:id"
            exact
            hasRole={["admin"]}
            component={UsersEditPage}
          />

          <Route path="/authenticate" component={AuthenticateContainer} />
          <Route path="/forgotpassword" component={ForgotPasswordContainer} />
          <Route path="/403" component={Unauthorized} />
          <Route path="/404" component={NotFound} />
          <Redirect to="/schoolcode" />
        </Switch>
      </div>
    </App>
  </div>
);

const mapStateToProps = state => ({
  isVisible: state.App.isSidenavVisible,
  roles: state.AuthenticatedUser.roles,
  isAuthenticated: state.AuthenticatedUser.isAuthenticated,
  isAdmin: state.AuthenticatedUser.isAdmin,
  loggedInAsSchool: state.AuthenticatedUser.loggedInAsSchool
});

const mapDispatchToProps = dispatch => () => ({
  logoutAsSchool: () => dispatch(logoutAsSchool())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
