import React, { Component } from "react";
import { Menu, MenuItem, Button, Popover, Position, Toaster, Intent } from "@blueprintjs/core";
import CopyText from 'react-copy-text';
import round from 'lodash/round';
import moment from 'moment';

const onCopyToast = Toaster.create()

export default class PaymentsListItem extends Component {
  
  constructor(props){
    super(props)
    this.state = { ibanText: '' }
  }
  
  copyIBAN = () => {
    this.setState({ ibanText: this.text.innerText })
  }

  onCopied = (text) => {
    onCopyToast.show({
      message: `${text} gekopiëerd naar plakbord`,
      intent: Intent.SUCCESS,
      iconName: `clipboard`
    })
  }
  
  render() {
    const { payment } = this.props;
    return (
      <div className="list-item row middle-xs">
        <div className="two-line col-xs">
          <p className="body-2">€ {round(payment.tutorFee / 100, 2)} - ten name van {payment.paymentDetail && payment.paymentDetail.name}</p>
          <div className="row middle-xs">
            <Button className="copy-text-button" onClick={this.copyIBAN} iconName="clipboard" />
            <p ref={ref => this.text = ref} value="his.state.textToCopy">{payment.paymentDetail && payment.paymentDetail.iban}</p>
            <CopyText text={this.state.ibanText} onCopied={this.onCopied} />
          </div>
        </div>
        <div className="col-xs">
          <p className="body-1">{moment(payment.createdAt).locale('nl').format('D MMM Y [om] HH:mm [uur]')}</p>
        </div>
        <span className="label-wrapper col-xs">
          {payment.payoutStatus === 'processed'
            ? <span className="label green">verwerkt</span>
            : <span className="label red">open</span>
          }
        </span>
        <Popover
          className="col-xs list-item-action"
          position={Position.LEFT_TOP}
          content={
            <Menu>
              <MenuItem
                onClick={() => this.props.onPayout(payment)}
                text="Betaling verwerken"
              />
            </Menu>
          }
          target={<Button id={"popOverClick"} iconName="bank-account" />}
        />
      </div>
    );
  }
}
