const getNameSpace = (key) => {
    return 'savvy.' + key;
}

const token = {
    set: (key, value) => window.localStorage.setItem(getNameSpace(key), value),
    get: (key) => window.localStorage.getItem(getNameSpace(key)),
    logout: () => {
        window.localStorage.removeItem(getNameSpace('refreshToken'))
        window.localStorage.removeItem(getNameSpace('accessToken'))
    }
};

export default token;