import * as endpoints from './endpoints'
import { Logger } from '../../util/logger';

export const COURSE_REQUEST = "COURSE_REQUEST";
const courseRequest = () => ({
    type: COURSE_REQUEST
})

export const COURSE_REQUEST_SUCCESS = "COURSE_REQUEST_SUCCESS";
const courseRequestSuccess = courses => ({
    type: COURSE_REQUEST_SUCCESS,
    courses
})

export const COURSE_REQUEST_ERROR = "COURSE_REQUEST_ERROR";
const courseRequestError = error => ({
    type: COURSE_REQUEST_ERROR,
    error
});

export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const toggleDialog = () => ({
    type: TOGGLE_DIALOG
})

export const VIEW_COURSE = "VIEW_COURSE";
export const viewCourse = course => ({
    type: VIEW_COURSE,
    course
})

export const CREATE_COURSE = "CREATE_COURSE";
export const createCourse = () => ({
    type: CREATE_COURSE
})

export const ON_COURSE_CHANGE = "ON_COURSE_CHANGE";
export const onCourseChange = (name, value) => ({
    type: ON_COURSE_CHANGE,
    name, value
})

export const SET_COURSE = "SET_COURSE";
export const setCourse = (course) => ({
    type: SET_COURSE,
    course
})

export const SAVE_COURSE_REQUEST = "SAVE_COURSE_REQUEST";
export const saveCourseRequest = () => ({
    type: SAVE_COURSE_REQUEST
})

export const SAVE_COURSE_REQUEST_SUCCESS = "SAVE_COURSE_REQUEST_SUCCESS";
export const saveCourseRequestSuccess = (course) => ({
    type: SAVE_COURSE_REQUEST_SUCCESS,
    course
})

export const SAVE_COURSE_REQUEST_FAILURE = "SAVE_COURSE_REQUEST_FAILURE";
export const saveCourseRequestFailure = (error) => ({
    type: SAVE_COURSE_REQUEST_FAILURE,
    error
})

export const SET_QUERY = "SET_QUERY";
export const setQuery = (query) => ({
    type: SET_QUERY,
    query
})

export const TOGGLE_REMOVE_COURSE_ALERT = "TOGGLE_REMOVE_COURSE_ALERT";
export const toggleRemoveCourseAlert = () => ({
    type: TOGGLE_REMOVE_COURSE_ALERT
})

export const SET_COURSE_TO_REMOVE = "SET_COURSE_TO_REMOVE";
export const setCourseToRemove = course => ({
    type: SET_COURSE_TO_REMOVE,
    course
})

export const REMOVE_COURSE_REQUEST = "REMOVE_COURSE_REQUEST";
export const removeCourse = () => (dispatch, getState) => {
    const { courseToRemove } = getState().Course;
    return endpoints.Course.destroy(courseToRemove.id)
        .then(() => {
            dispatch(removeCourseRequestSuccess())
            dispatch(toggleRemoveCourseAlert())
        })
        .catch(error => Logger.error('REMOVE_COURSE_ERROR', error))
}

export const REMOVE_COURSE_REQUEST_SUCCESS = "REMOVE_COURSE_REQUEST_SUCCESS";
const removeCourseRequestSuccess = () => ({
    type: REMOVE_COURSE_REQUEST_SUCCESS
})

const fetchCourses = () => (dispatch, getState) => {
    dispatch(courseRequest())
    return endpoints.Course.find()
        .then(courses => dispatch(courseRequestSuccess(courses)))
        .catch(error => dispatch(courseRequestError(error)))
}

const shouldFetchCourses = state => {
    // if (!state.courses.length) {
    //     return true;
    // }
    if (state.isFetching) {
        return false;
    }
    return true;
}

export const fetchCoursesIfNeeded = () => (dispatch, getState) => {
    const { Course } = getState();
    if (shouldFetchCourses(Course)) {
        return dispatch(fetchCourses())
    }
}

export const saveOrUpdateCourse = () => (dispatch, getState) => {
    const course = getState().Course.course
    course.schools = [getState().AuthenticatedUser.loggedInAsSchool]
    dispatch(saveCourseRequest())
    const request = (course.id) ? endpoints.Course.update(course.id, course) : endpoints.Course.create(course)
    return request
        .then(course => dispatch(saveCourseRequestSuccess(course)))
        .catch(error => dispatch(saveCourseRequestFailure(error)))
}