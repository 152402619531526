import React, { Component } from "react";
import BarGroup from "./StatsPercentageBar/BarGroup";
import Bar from "./StatsPercentageBar/Bar";
import { NonIdealState } from "@blueprintjs/core";
import sumBy from 'lodash/sumBy'
import Graph from './Graph'
import Heatmap from "./Heatmap/Heatmap";

export default class QuestionsChart extends Component {
    render() {
        const { questions, questionsHeatmap } = this.props
        
        return (
            <Graph title="Hulpvragen" description="Het aantal hulpvragen dat is gesteld per vak en in welk leerjaar en niveau deze vragen zijn gesteld">
                <div className="group-graphs row">
                    {this.renderQuestionsBarChart(questions)}
                    {this.renderQuestionsHeatmap(questionsHeatmap)}
                </div>
            </Graph>
        );
    }

    renderQuestionsBarChart(questions) {
        if (!questions || !questions.length) {
            return <NonIdealState description="Geen hulpvragen data beschikbaar" visual="horizontal-bar-chart-desc" />
        }

        const totalQuestions = sumBy(questions, 'questions')
        return (
            <BarGroup
                className="col-xs-12 col-md-6"
                labels={["Vak", "hulpvragen"]}
            >
                {questions.map((item, i) => <Bar key={i} name={item.course} count={item.questions} total={totalQuestions} />)}
            </BarGroup>
        )
    }

    renderQuestionsHeatmap(heatmap) {
        if (!heatmap) {
            return <NonIdealState description="Geen hulpvragen data beschikbaar" visual="horizontal-bar-chart-desc" />
        }

        return <Heatmap className="col-xs-12 col-md-6" labels={heatmap.labels} dataset={heatmap.dataset} />
    }
}
