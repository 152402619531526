import React from 'react'
import LevelNavbarContainer from '../../containers/Levels/Navbar';
import LevelCreateContainer from '../../containers/Levels/Create';

export default (props) => (
    <div>
        <LevelNavbarContainer
            {...props}
            title="Leerniveau aanmaken"
            hasSearch={false}
            hasActions={false}
        />
        <LevelCreateContainer {...props} />
    </div>
)