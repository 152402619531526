import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LevelsNavbar from "../../components/Levels/LevelsNavbar";
import { setQuery } from '../../store/Level/actions';
import { openSidenav } from '../../store/App/actions';

const LevelNavbarContainer = (props) => (<LevelsNavbar {...props} />);

const mapDispatchToProps = dispatch => ({
    onSearch: (query) => dispatch(setQuery(query)),
    openSidenav: () => dispatch(openSidenav())
})

export default withRouter(connect(null, mapDispatchToProps)(LevelNavbarContainer))