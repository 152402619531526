import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CourseNavbar from "../components/Courses/CourseNavbar";
import CourseEditDialog from "../components/Courses/CourseEditDialog";
import CourseList from "../components/Courses/CourseList";
import { fetchCoursesIfNeeded, toggleDialog, viewCourse, setCourse, saveOrUpdateCourse, createCourse, setQuery, toggleRemoveCourseAlert, setCourseToRemove, removeCourse } from '../store/Course/actions';
import { getVisibleCourses } from '../store/Course/reducers';
import { openSidenav } from '../store/App/actions';

class CoursesContainer extends Component {
    componentDidMount() {
        this.props.fetchCoursesIfNeeded()
    }
    
    render() {
        return (
            <div>
                <CourseNavbar {...this.props} />
                <CourseEditDialog {...this.props} />
                <CourseList {...this.props} />
              </div>
        )
    }
}

const mapStateToProps = state => {
    const { Course } = state;
    return { 
        ...Course,
        courses: getVisibleCourses(state, Course.query),
        school: state.AuthenticatedUser.loggedInAsSchool,
        isAdmin: state.AuthenticatedUser.isAdmin,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCoursesIfNeeded: () => dispatch(fetchCoursesIfNeeded()),
        toggleDialog: () => dispatch(toggleDialog()),
        onClick: (course) => dispatch(viewCourse(course)),
        onEdit: (e, course) => {
            dispatch(setCourse(course))
            dispatch(toggleDialog())
        },
        onChange: (course) => dispatch(setCourse(course)),
        onSubmit: (e) => {
            e.preventDefault();
            dispatch(saveOrUpdateCourse())
        },
        onCreate: (e) => dispatch(createCourse()),
        onSearch: (e) => dispatch(setQuery(e.target.value)),
        toggleRemoveCourseAlert: (e, course) => {
            dispatch(setCourseToRemove(course))
            dispatch(toggleRemoveCourseAlert())
        },
        handleRemoveCourse: (e) => dispatch(removeCourse()),
        openSidenav: () => dispatch(openSidenav())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoursesContainer))