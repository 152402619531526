import React from 'react'
import SchoolDetailNavbar from '../../components/SchoolDetail/SchoolDetailNavbar'
import SchoolDetailForm from '../../components/SchoolDetail/SchoolDetailForm'
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { getSchoolById } from '../../store/School/selectors';
import { fetchSchoolsIfNeeded, saveSchool } from '../../store/School/actions';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import omit from 'lodash/omit'

class SchoolEditContainer extends React.Component {
    componentDidMount() {
        this.props.fetchSchool()
    }

    render() {
        const props = this.props

        if (props.isLoading || !props.school) {
            return (<NonIdealState visual={<Spinner />}/>)
        }

        if (!props.isLoading && !props.school) {
            return (<Redirect to={`/404`} />)
        }

        return (
            <React.Fragment>
                <SchoolDetailNavbar {...props} />
                <SchoolDetailForm {...props} />
            </React.Fragment>
        )
    }
} 

const mapStateToProps = (state, ownProps) => {
    return {
        school: getSchoolById(state, ownProps.match.params.id),
        isSaving: state.schools.isSaving,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchSchool: () => dispatch(fetchSchoolsIfNeeded()),
        onSubmit: (values) => {
            const school = omit(values, ['users', 'years', 'levels', 'courses', 'rooms'])
            dispatch(saveSchool(school))
        },
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SchoolEditContainer)
)