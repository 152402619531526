import React from 'react'
import SchoolList from '../../components/Schools/SchoolList'
import SchoolNavbar from '../../components/Schools/SchoolNavbar'
import { connect } from 'react-redux';
import { openSidenav } from '../../store/App/actions';
import { fetchSchoolsIfNeeded, setSchoolSearchQuery } from '../../store/School/actions';
import { isLoading } from '../../store/School/selectors';
import { getVisibleSchools } from '../../store/School/reducers';
import withRouter from 'react-router/withRouter';
import { loginAsSchool } from '../../store/AuthenticatedUser/actions';

class SchoolListContainer extends React.Component {
    componentDidMount() {
        this.props.fetchSchools()
    }

    render() {
        const props = this.props
        
        return (
            <React.Fragment>
                <SchoolNavbar {...props} />
                <SchoolList {...props} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    isLoading: isLoading(state),
    schools: getVisibleSchools(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    openSidenav: () => dispatch(openSidenav()),
    fetchSchools: () => dispatch(fetchSchoolsIfNeeded()),
    loginAsSchool: school => dispatch(loginAsSchool(school)),
    onSearch: e => dispatch(setSchoolSearchQuery(e.target.value))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolListContainer))