import React, { Component } from 'react';
// TODO: Are these colors ok? Or don't use multiple colors
const backgroundColors = ['F9AF00', '09E85E', '00BFFF'];
const randomBackground = () => backgroundColors[Math.floor(Math.random() * backgroundColors.length)]

class Avatar extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.src !== this.props.src || nextProps.placeholder !== this.props.placeholder
	}

	render() {
		return this.props.src 
			? (<img className="avatar" src={this.props.src} alt={this.props.placeholder} />)
			// TODO: Remove placeholder.com dep
			: (<img className="avatar" src={`https://via.placeholder.com/50x50/${randomBackground()}/FFFFFF?text=${this.props.placeholder[0]}`} alt={this.props.placeholder} />)
	}
}

export default Avatar