import { Component } from 'react';
import './App.css';
import { withRouter } from 'react-router-dom'
import * as drift from '../../util/drift'

class App extends Component {
  componentWillMount() {
    drift.page(this.props.location.pathname)
    drift.identity()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      drift.page(nextProps.location.pathname)
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(App);
