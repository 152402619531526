import auth from "../auth";
import { enableSidenav } from "../App/actions";
import School from '../School/endpoints';
import token from "../token";
import * as drift from '../../util/drift'

export const AUTHENTICATE_REQUEST = "AUTHENTICATE_REQUEST";
const authenticateRequest = email => ({
  type: AUTHENTICATE_REQUEST,
  email
});

export const AUTHENTICATE_REQUEST_SUCCESS = "AUTHENTICATE_REQUEST_SUCCESS";
const authenticateRequestSuccess = () => ({
  type: AUTHENTICATE_REQUEST_SUCCESS
});

export const AUTHENTICATE_REQUEST_FAILURE = "AUTHENTICATE_REQUEST_FAILURE";
const authenticateRequestFailure = () => ({
  type: AUTHENTICATE_REQUEST_FAILURE
});

export const AUTHENTICATED = "AUTHENTICATED";
export const authenticateSuccess = (refreshToken, accessToken) => ({
  type: AUTHENTICATED,
  refreshToken,
  accessToken
});

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const setAccessToken = (accessToken) => ({
  type: SET_ACCESS_TOKEN,
  accessToken
})

export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const setRefreshToken = refreshToken => ({
  type: SET_REFRESH_TOKEN,
  refreshToken
})

export const subscribeToAuthenticationComplete = () => (
  dispatch,
  getState,
  { io, http }
) => {
  io.socket.on("session", message => {
    if (message.verb === "updated") {
      auth.authenticate(message.data.refreshToken, message.data.accessToken);
      if (auth.isAdmin()) {
        School.findOne(auth.decode().school)
          .then(school => {
            dispatch(authenticateSuccess(message.data.refreshToken, message.data.accessToken));
            dispatch(enableSidenav());
            dispatch(setLoggedInSchool(school))
          })
          .catch(error => {
            dispatch(authenticateSuccess(message.data.refreshToken, message.data.accessToken));
            dispatch(enableSidenav());
          })
      } else {
        dispatch(authenticateSuccess(message.data.refreshToken, message.data.accessToken));
        dispatch(enableSidenav());
      }

      drift.identity()
    }
  });
};

export const authenticate = email => (dispatch, getState, { io }) => {
  dispatch(authenticateRequest(email));
  io.socket.post(
    "/v1/auth/session",
    {
      email: email,
      callbackUrl: window.location.origin + "/authenticate"
    },
    (res, jwr) => {
      if (jwr.statusCode === 200) {
        dispatch(authenticateRequestSuccess());
      } else {
        dispatch(authenticateRequestFailure())
      }
    }
  );
};

export const LOGIN_AS_SCHOOL_REQUEST = "LOGIN_AS_SCHOOL_REQUEST";
const loginSchoolRequest = () => ({
    type: LOGIN_AS_SCHOOL_REQUEST
})
export const LOGIN_AS_SCHOOL_SUCCESS = "LOGIN_AS_SCHOOL_SUCCESS";
const loginAsSchoolSuccess = () => ({
    type: LOGIN_AS_SCHOOL_SUCCESS
})
export const LOGIN_AS_SCHOOL_FAILURE = "LOGIN_AS_SCHOOL_FAILURE";
const loginAsSchoolFailure = (error) => ({
    type: LOGIN_AS_SCHOOL_FAILURE,
    error
})

export const loginAsSchool = (schoolID) => (dispatch, getState, { http }) => {
    dispatch(loginSchoolRequest())
    http.post('/admin/loginAsSchool', { schoolId: schoolID })
        .then(res => res.data)
        .then(accessToken => {
            token.set('accessToken', accessToken.accessToken)
            dispatch(setAccessToken(accessToken.accessToken))
            return School.findOne(schoolID)
        })
        .then(school => {
            dispatch(loginAsSchoolSuccess())
            dispatch(setLoggedInSchool(school))
        })
        .catch(error => dispatch(loginAsSchoolFailure(error)))
};

export const SET_LOGGED_IN_SCHOOL = "SET_LOGGED_IN_SCHOOL";
export const setLoggedInSchool = school => ({
    type: SET_LOGGED_IN_SCHOOL,
    school
})

export const logoutAsSchool = () => (dispatch, getState, { http }) => {
    http.post('/auth/refreshToken', { refreshToken: token.get('refreshToken') })
        .then(res => res.data)
        .then(data => {
            token.set('accessToken', data.token)
            dispatch(setAccessToken(data.token))
            dispatch(setLoggedInSchool(null))
        })
        .catch(error => console.error('error', error))
}

export const LOGOUT = "LOGOUT";
export const logout = () => ({ type: LOGOUT });

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const fetchRefreshToken = () => ({ type: REFRESH_TOKEN_REQUEST });

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const refreshTokenFetched = () => ({ type: REFRESH_TOKEN_SUCCESS });

export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const refreshTokenFailure = error => ({ type: REFRESH_TOKEN_FAILURE, error })

export const loginWithAccessToken = token => (dispatch, getState, { http }) => {
  dispatch(setAccessToken(token));
  return http.post('/auth/accessToken', { accessToken: token })
    .then(res => res.data)
    .then(data => {
      auth.authenticate(data.refreshToken, token);
      dispatch(refreshTokenFetched())
      dispatch(authenticateSuccess(data.refreshToken, token))
      dispatch(enableSidenav())
    })
    .catch(err => dispatch(refreshTokenFailure(err)))
}