import React, { Component } from 'react';
import { Button,
         Intent,
         NonIdealState,
        Spinner } from "@blueprintjs/core";
import TeacherListItem from './TeacherListItem';

export default class TeacherList extends Component {
  render() {
    if (this.props.isFetching) {
      return (<NonIdealState action={<Spinner />} />)
    }

    if (!this.props.teachers.length) {
      return (<NonIdealState
        title="Geen docenten"
        description={`Er zijn geen docenten beschikbaar voor ${this.props.schoolName}. Voeg er een toe!`}
        action={
        <Button
          intent={Intent.PRIMARY}
          onClick={this.props.toggleDialog}
          className="pt-large"
          text="Docent toevoegen" />
        }>
      </NonIdealState>)
    }

    return (
      <div>
        <div className="list">
          {this.props.teachers.map(teacher => (
            <TeacherListItem {...this.props} teacher={teacher} key={teacher.id} onClick={(e) => this.props.toggleDialog(e, teacher)} />
          ))}
        </div>
      </div>
    )
  }
}
