import React, { Component } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { AnchorButton } from "@blueprintjs/core";

registerPlugin(FilePondPluginFileValidateType)

export default class TeacherImport extends Component {
  render() {
    if (this.props.isStagingImport) {
      return null
    }

    return (
      <div className="bulk-import">
        <h4>Sleep je bestand hierheen</h4>
        {/* FilePond docs https://pqina.nl/filepond/docs/patterns/api/filepond-instance/#labels */}
        <FilePond 
          labelIdle={'Sleep je (.csv) bestand hierheen of <span class="filepond--label-action"> upload </span> een bestand'}
          labelInvalidField={'Ongeldig bestand'}
          labelFileLoading={'Laden...'}
          labelFileLoadError={'Foutmelding tijdens laden'}
          labelFileProcessing={'Uploaden...'}
          labelFileProcessingComplete={'Upload voltooid'}
          labelFileProcessingAborted={'Upload geannuleerd'}
          labelFileProcessingError={'Foutmelding tijdens uploaden'}
          labelTapToCancel={'Tik op te annuleren'}
          labelTapToRetry={'Tik om opnieuw te proberen'}
          labelButtonRemoveItem={'Verwijder'}
          labelButtonAbortItemLoad={'Onderbreek'}
          labelButtonRetryItemLoad={'Probeer opnieuw'}
          labelButtonAbortItemProcessing={'Annuleer'}
          labelButtonRetryItemProcessing={'Probeer opnieuw'}
          labelButtonProcessItem={'Uploaden'}
          allowMultiple={false}
          acceptedFileTypes={['text/csv']}
          onaddfile={this.props.onFileUpload} 
          />
        <AnchorButton href="/downloads/csv-docenten-import.csv" text="Voorbeeld CSV downloaden" className="pt-intent-primary inverted" download/>
      </div>
    )
  }
}
