import React, { Component } from 'react';
import map from 'lodash/map'
import { Button, Intent } from "@blueprintjs/core";

export default class TeacherMapImport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      keyMap: {}
    }
  }

  onChange(e, csvHeader) {
    const { keyMap } = this.state
    keyMap[e.target.value] = csvHeader
    this.setState({ keyMap })
  }

  render() {
    if (this.props.isStagingImport === false) {
      return null
    }

    const { headersFromImport, supportedHeaders } = this.props.csvHeaders

    return (
      <div className="map-bulk-import">
        <h4>Koppeling aangeven</h4>
        <p className="body-1 explain-mapping">Selecteer de velden uit Savvy die overeenkomen met de geimporteerde headers uit het CSV bestand</p>
        <div className="bulk-mapper">
          <div className="row">
            <div className="col-xs-6">
              <p className="body-2 center">CSV headers</p>
            </div>
            <div className="col-xs-6">
              <p className="body-2">Savvy Learning</p>
            </div>
          </div>
          <div className="row map-headers">
            {map(headersFromImport, header => (
              <React.Fragment key={header}>
                <div className="col-xs-6 map-header">
                  <p className="body-1 imported-header">{header}</p>
                </div>
                <div className="col-xs-6 map-header">
                  <div className="pt-select pt-fill">
                    <select defaultValue={0} onChange={e => this.onChange(e, header)}>
                      <option disabled value={0}>Kies een veld...</option>
                      {map(supportedHeaders, (value, key) =>
                        <option key={key} value={key}>{value}</option>
                      )}
                    </select>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <Button text="Start import" intent={Intent.PRIMARY} onClick={() => this.props.startImportWithMap(this.state.keyMap, this.props.history)} loading={this.props.isImporting}/>
      </div>
    )
  }
}
