import React from 'react';
import isEmpty from 'lodash/isEmpty'

const SchoolDetailNavbar = (props) => (
  <nav className="pt-navbar row middle-xs">
    <div className="pt-navbar-group col-xs">
      <button className="pt-button pt-icon-arrow-left pt-large show-up-to-desktop" onClick={() => props.history.goBack()}></button>
      <h3 className="pt-navbar-heading pt-fill">{isEmpty(props.school.name) ? "School aanmaken" : props.school.name}</h3>
    </div>
  </nav>
)

export default SchoolDetailNavbar