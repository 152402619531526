import * as ActionTypes from './actions';

const initialState = {
  isSidenavVisible: true,
  isSidenavOpen: false,
  isLogoutDisabled: false,
  isMobileApp: false
};

const App = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_SIDENAV:
      return {
        ...state,
        isSidenavVisible: !state.isSidenavVisible
      }
    case ActionTypes.DISABLE_SIDENAV:
      return {
        ...state,
        isSidenavVisible: false
      }
    case ActionTypes.ENABLE_SIDENAV:
      return {
        ...state,
        isSidenavVisible: true
      }
    case ActionTypes.OPEN_SIDENAV:
      return {
        ...state,
        isSidenavOpen: true
      }
    case ActionTypes.CLOSE_SIDENAV:
      return {
        ...state,
        isSidenavOpen: false
      }
    case ActionTypes.DISABLE_LOGOUT:
      return {
        ...state,
        isLogoutDisabled: true,
        isMobileApp: true
      }
    default:
      return state;
  }
};

export default App;
