import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';
import config from './config';
import env from '../util/env';
import auth from './auth';

let io = sailsIOClient(socketIOClient);
io.sails.url = config.baseUrl();
io.sails.environment = env;

io.sails.headers = {
    Authorization: `Bearer ${auth.accessToken()}`
}

const originalRequest = io.socket.request.bind(io.socket);
io.socket.request = function (options, cb) {
    console.log('Intercepting ws req', options.url);
    options.headers = options.headers || {};
    options.headers['Authorization'] = `Bearer ${auth.accessToken()}`
    originalRequest(options, cb);
};

window.io = io

export default io;