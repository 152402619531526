import * as ActionTypes from "./actions";
import unionBy from 'lodash/unionBy';
import orderBy from 'lodash/orderBy';

const initialState = {
  email: "",
  isAuthenticating: false,
  loading: false,
  isAuthenticated: false,
  isForgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordMessage: null,
  users: []
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_WITH_EMAIL:
      return {
        ...state,
        email: action.email
      };
    case ActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isForgotPasswordLoading: true,
        forgotPasswordError: null
      }
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordLoading: false,
        forgotPasswordMessage: action.data.data.message
      }
    case ActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isForgotPasswordLoading: false,
        forgotPasswordError: action.error
      }
    case ActionTypes.ADD_USER:
      return {
        ...state,
        users: orderBy(unionBy(action.users, state.users, 'id'), 'id')
      }
    default:
      return state;
  }
};

export default User;
