import React from "react";
import { Icon } from "@blueprintjs/core";

const SchoolLink = props => (
  <a className="sidenav-item savvy" {...props}>
    <li className="body-2">
      <Icon iconName="pt-icon-chevron-left" />
      {props.children}
    </li>
  </a>
);

export default SchoolLink;
