import React from 'react';
import {
    connect
} from 'react-redux';
import LevelsEditForm from '../../components/Levels/LevelsEditForm';

import { isSaving } from '../../store/Level/selectors';
import { saveForm } from '../../store/Level/actions';

const mapStateToProps = (state) => ({
    isSaving: isSaving(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit: form => dispatch(saveForm(form)).then(() => ownProps.history.push('/levels')),
})

export const LevelCreateContainer = (props) => (<LevelsEditForm {...props} />)
export default connect(mapStateToProps, mapDispatchToProps)(LevelCreateContainer)