import React from 'react'
import SchoolDetailNavbar from '../../components/SchoolDetail/SchoolDetailNavbar'
import SchoolDetailForm from '../../components/SchoolDetail/SchoolDetailForm'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { saveSchoolRequestSuccess, saveSchoolRequestError, saveSchoolRequest } from '../../store/School/actions';
import School from '../../store/School/endpoints';

class SchoolEditContainer extends React.Component {
    render() {
        const props = this.props

        return (
            <React.Fragment>
                <SchoolDetailNavbar {...props} />
                <SchoolDetailForm {...props} />
            </React.Fragment>
        )
    }
} 

const mapStateToProps = (state, ownProps) => {
    return {
        school: {
            name: '',
            address: {
                street: '',
                streetnumber: '',
                zip: '',
                city: '',
            },
            tutorFee: 700
        },
        isSaving: state.schools.isSaving,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: (values) => {
            dispatch(saveSchoolRequest())
            School.create(values)
                .then(school => dispatch(saveSchoolRequestSuccess(school)))
                .then(() => ownProps.history.push(`/schools`))
                .catch(error => dispatch(saveSchoolRequestError(error)))
        },
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SchoolEditContainer)
)