import React, { Component } from 'react';
import { NonIdealState,
         Spinner } from "@blueprintjs/core";
import TutorRequestCardItem from './TutorRequestCardItem';
import TutorCardItem from './TutorCardItem';
import Header from '../Header/Header';

export default class TutorsCards extends Component {
  render() {
    if (this.props.isFetching) {
      return (<NonIdealState action={<Spinner />} />)
    }

    if (!this.props.tutors.length && !this.props.tutorRequests.length) {
      return (<NonIdealState
        title="Geen bijlesscholieren"
        description={`Er zijn geen bijlesscholieren beschikbaar voor ${this.props.schoolName}. Vraag aan de hogerejaars of ze bijles willen geven`}>
      </NonIdealState>)
    }

    return (
      <div className="cards-view">
        {this.props.tutorRequests.length 
          ? (
          <div className="open-requests">
            <Header title="Openstaande verzoeken" label={this.props.tutorRequests.length} />
            <div className="cards">
              {this.props.tutorRequests.map(tutor => <TutorRequestCardItem key={tutor.id} tutor={tutor} {...this.props} />)}
            </div>
          </div>
        ) : null}
        
        <div className="tutor-students">
          <Header title="Bijlesscholieren" label={this.props.tutors.length} />
          <div className="cards">
            {this.props.tutors.map(tutor => <TutorCardItem key={tutor.id} tutor={tutor} {...this.props} />)}
          </div>
        </div>
      </div>
    )
  }
}
