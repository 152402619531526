import React, { Component } from "react";
import { connect } from "react-redux";
import Header from '../components/Header/Header';
import StatsCard from '../components/Stats/StatsCardComponent';
import StatsLineChart from '../components/Stats/StatsLineChartComponent';
import TalentAndWeaknessChart from '../components/Stats/StatsPercentageBar/TalentAndWeaknessChart';
import {
  NonIdealState,
  Spinner
} from '@blueprintjs/core';
import { openSidenav } from "../store/App/actions";
import { fetchStats } from "../store/School/actions";
import {
  getLabels,
  totalQuestions,
  totalTutoring,
  activityDataset,
  totalSignups,
  activeUsersLastMonth,
  talentsDataset,
  weaknessesDataset,
  tutorsDataset,
  signupsHeatmap,
  period,
  tutoring,
  questions,
  questionsHeatmap,
  paymentRequestsDataset,
  usageHeatmap,
  questionsAndTutoringPerDay
} from "../store/School/selectors";
import AvailableTutosChart from "../components/Stats/AvailableTutorsChart";
import QuestionsChart from "../components/Stats/QuestionsChart";
import SignupsHeatmap from "../components/Stats/SignupsHeatmap";
import PaymentRequestsChart from "../components/Stats/PaymentRequestsChart";
import UsageHeatmap from "../components/Stats/UsageHeatmap";
import { isAdmin } from "../store/AuthenticatedUser/selectors";
import QuestionsTutoringDayChart from "../components/Stats/QuestionsTutoringDayChart";
import StatsFilter from "../components/Stats/StatsFilter";
import { getLevels, getSchoolYears, getYears, setFilter, resetFilter } from "../store/Stats";

class StatsContainer extends Component {
  componentDidMount() {
    this.props.fetchStats()
  }

  constructor(props) {
    super(props);
    this.state = {
      showFilters: false
    }
  }

  toggleStatsFilter() {
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  render() {
    return (
      <div>
        <nav className="pt-navbar row middle-xs">
          <div className="pt-navbar-group col-xs">
            <button
              className="pt-button pt-icon-menu pt-large show-up-to-desktop"
              onClick={this.props.openSidenav}
            />
            <h3 className="pt-navbar-heading pt-fill">Statistieken</h3>
            <button onClick={() => this.toggleStatsFilter()} className="pt-button pt-intent-success pt-icon-filter pt-large"></button>
          </div>
        </nav>
        <StatsFilter
          showFilters={this.state.showFilters}
          toggleStatsFilter={() => this.toggleStatsFilter()}
          years={this.props.years}
          levels={this.props.levels}
          schoolYears={this.props.schoolYears}
          setFilter={this.props.setFilter}
          resetFilter={this.props.resetFilter}
        />
        <div>
          {this.props.isLoading ? (
            <NonIdealState visual={<Spinner />} />
          ) : (
            <div className="cards-view">
              <Header title={`Overzicht ${this.props.period}`} />
              <div className="cards">
                <StatsCard value={this.props.totalSignups} description="Aanmeldingen" />
                <StatsCard value={this.props.activeUsersLastMonth} description="Actieve gebruikers" />
                <StatsCard value={this.props.totalQuestions + this.props.totalTutors} description="Totale activiteit" />
                <StatsCard value={this.props.totalQuestions} description="Hulpvragen" />
                <StatsCard value={this.props.totalTutors} description="Bijlesafspraken" />
              </div>
              <div className="charts">
                <StatsLineChart
                  title="Activiteit"
                  labels={this.props.labels}
                  datasets={this.props.activityDataset}
                  explainContent="In activiteit zie je hoevaak leerlingen elkaar om hulp vragen en hoeveel leerlingen bijles geven of krijgen."
                />

                <TalentAndWeaknessChart {...this.props} />
                <AvailableTutosChart {...this.props} />
                <QuestionsChart {...this.props} />
                <SignupsHeatmap labels={this.props.signupsHeatmap.labels} dataset={this.props.signupsHeatmap.dataset} />
                <UsageHeatmap labels={this.props.usageHeatmap.labels} dataset={this.props.usageHeatmap.dataset} />
                <QuestionsTutoringDayChart labels={this.props.questionsAndTutoringPerDay.labels} dataset={this.props.questionsAndTutoringPerDay.dataset} />
                <PaymentRequestsChart {...this.props} labels={this.props.labels} dataset={this.props.paymentRequestsDataset} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

StatsContainer.defaultProps = {
  labels: [],
  totalSignups: 0,
  totalQuestions: 0,
  totalTutors: 0,
  activityDataset: [
    {
      label: 'Hulpvragen',
      data: [0],
    },
    {
      label: 'Bijlesafspraken',
      data: [0],
    },
    {
      label: 'Totale activiteit',
      data: [0],
    }
  ],
  activeUsersLastMonth: 0,
  talents: [],
  weaknesses: [],
  tutors: [],
  usageHeatmap: {
    labels: [],
    dataset: []
  },
  questionsAndTutoringPerDay: {
    labels: [],
    dataset: []
  }
};

const mapStateToProps = state => ({
  isLoading:                  state.schools.isLoading,
  totalSignups:               totalSignups(state),
  labels:                     getLabels(state),
  totalQuestions:             totalQuestions(state),
  totalTutors:                totalTutoring(state),
  activityDataset:            activityDataset(state),
  activeUsersLastMonth:       activeUsersLastMonth(state),
  talents:                    talentsDataset(state),
  weaknesses:                 weaknessesDataset(state),
  tutors:                     tutorsDataset(state),
  signupsHeatmap:             signupsHeatmap(state),
  period:                     period(state),
  tutoring:                   tutoring(state),
  questions:                  questions(state),
  questionsHeatmap:           questionsHeatmap(state),
  isAdmin:                    isAdmin(state),
  paymentRequestsDataset:     paymentRequestsDataset(state),
  usageHeatmap:               usageHeatmap(state),
  questionsAndTutoringPerDay: questionsAndTutoringPerDay(state),
  levels:                     getLevels(state),
  schoolYears:                getSchoolYears(state),
  years:                      getYears(state),
});

const mapDispatchToProps = dispatch => ({
  fetchStats: () => dispatch(fetchStats()),
  openSidenav: () => dispatch(openSidenav()),
  setFilter: filter => {
    dispatch(setFilter(filter))
    dispatch(fetchStats())
  },
  resetFilter: () => {
    dispatch(resetFilter())
    dispatch(fetchStats())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatsContainer)
