import get from 'lodash/get'
import intersection from 'lodash/intersection'
import decode from "jwt-decode";

export const loggedInSchool = state => {
    if (isAdmin(state)) {
        return get(state.AuthenticatedUser, 'loggedInAsSchool.id', state.AuthenticatedUser.school)
    }
    return state.AuthenticatedUser.school
}
export const isAdmin = state => state.AuthenticatedUser.isAdmin

export const isSchoolAdmin = state => {
    const accessToken = state.AuthenticatedUser.accessToken
    if (!accessToken) {
        return false
    }

    try {
        const payload = decode(accessToken)
        return intersection(payload.roles, ['admin', 'school administrator'])
    } catch(err) {
        return false
    }
}