import React, { Component } from 'react';
import { NonIdealState, Button, Intent, Alert } from "@blueprintjs/core";
import LevelsListItem from './LevelsListItem';
import get from 'lodash/get';

export default class LevelsList extends Component {
  render() {
    if (!this.props.levels.length) {
      return (<NonIdealState
          title="Geen leerniveau's"
          description={`Er zijn geen leerniveau's beschikbaar voor de ${get(this.props.school, 'name', '')}. Voeg er een toe!`}
          action={
            <Button
              intent={Intent.PRIMARY}
              onClick={this.props.toggleDialog}
              className="pt-large"
              text="Leerniveau toevoegen" />
          }>
        </NonIdealState>)
    }

    return (
      <div>
        <div className="list">
          {this.props.levels.map(level => (
            <LevelsListItem {...this.props} level={level} key={level.id}></LevelsListItem>
          ))}
        </div>
        <Alert
          intent={Intent.DANGER}
          isOpen={this.props.isRemoveLevelAlertOpen}
          confirmButtonText="Verwijderen"
          cancelButtonText="Annuleren"
          onConfirm={() => this.props.onDelete(this.props.level)}
          onCancel={this.props.toggleRemoveLevelAlert}>
          <p>Als je een leerniveau verwijderd moeten alle leerlingen die zich hebben aangemeld met dat niveau zich opnieuw aanmelden. Weet je zeker dat je het leerniveau <b>{this.props.level && this.props.level.name}</b> wilt verwijderen?</p>
        </Alert>
      </div>
    )
  }
}
