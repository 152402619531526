import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";
import env from "../util/env";
import io from "./io";
import http from "./http";

let configureStore = null;

if (env === "production") {
  configureStore = preloadedState =>
    createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(thunk.withExtraArgument({ io, http }))
    );
} else {
  configureStore = preloadedState => {
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const store = createStore(
      rootReducer,
      preloadedState,
      compose(
        applyMiddleware(thunk.withExtraArgument({ io, http }), createLogger()),
        devTools()
      )
    );

    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept("./reducers", () => {
        store.replaceReducer(rootReducer);
      });
    }

    return store;
  };
}

export default configureStore;
