import React, { Component } from 'react';
import { Button, Intent, Tooltip, Position } from "@blueprintjs/core";

export default class TeacherNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = { query: this.props.query };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState(
      { query: e.target.value },
      () => this.props.onSearch(this.state.query)
    );
  }

  render() {
    return (
      <nav className="pt-navbar row middle-xs">
        <div className="pt-navbar-group col-xs">
          <button  className="pt-button pt-icon-menu pt-large show-up-to-desktop" onClick={() => this.props.openSidenav()}></button>
          <h3 className="pt-navbar-heading hide-up-to-tablet-portrait">Docenten</h3>
          <input className="pt-input pt-fill" placeholder="Zoeken op e-mailadres of naam..." type="text" name="query" value={this.state.query} onChange={this.onChange}/>
          <div className="pt-navbar--button-group">
            <Tooltip content="CSV import" position={Position.BOTTOM}>
              <Button onClick={() => this.props.history.push(`/teachers/import`)} intent={Intent.NONE} iconName="import" className="pt-large" />
            </Tooltip>
            <Tooltip content="Nieuwe docent" position={Position.BOTTOM}>
              <Button onClick={this.props.toggleDialog} intent={Intent.SUCCESS} iconName="plus" className="pt-large" />
            </Tooltip>
          </div>
        </div>
      </nav>
    )
  }
}
