import React, { Component } from "react";
import { sum } from 'lodash'

const HeatMapItem = ({ value, total }) => {
  const percentage = (value && total)
    ? ((value / (total / 100)) / 100) + .2
    : .2

  return (
    <p className="caption heatmap-content col-xs" style={{
      backgroundColor: `rgba(0, 191, 255, ${percentage})`
    }}>
      {value}
    </p>
  )
}

export default class Heatmap extends Component {
  render() {
    const { labels, dataset, className } = this.props
    

    return (
      <div className={`heatmap ${className}`}>
        <div className="row">
          <div className="heatmap-subheaders">
            <div className="caption heatmap-subheader"></div>
            {labels.map((label, index) => <p className="caption heatmap-content-subheader" key={index}>{label}</p>)}
          </div>
          
          {dataset.map((set, index) => {
            const total = sum(set.data)

            return (
              <div className="heatmap-item col-xs" key={index}>
                <p className="caption heatmap-subheader">{set.label}</p>
                {set.data.map((value, _index) => (
                  <HeatMapItem key={_index} value={value} total={total} />
                ))}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}