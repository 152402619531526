import React, { Component } from 'react';


export default class TutorsList extends Component {
  render() {
    return (
      <h5 className="header-has-label">
        <span className="header-five">{this.props.title}</span>
        { this.props.label ?
          <span className="label-wrapper">
            <span className="label red">
              {this.props.label}
            </span>
          </span>
        : null }
      </h5>
    )
  }
}
