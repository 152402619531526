import set from 'lodash/set';

import * as ActionTypes from "./actions";
import * as Selectors from "./selectors";

const schools = (
  state = {
    isOpen: false,
    isLoading: false,
    schools: [],
    query: "",
    isSaving: false,
    selectedSchool: {},
    stats: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SCHOOLS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.SCHOOLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        schools: action.schools
      };
    case ActionTypes.SCHOOLS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case ActionTypes.SET_SCHOOL_SEARCH_QUERY:
      return {
        ...state,
        query: action.query
      };
    case ActionTypes.TOGGLE_SCHOOL_DIALOG:
      return {
        ...state,
        isOpen: !state.isOpen,
        selectedSchool: action.school || {}
      };
    case ActionTypes.SAVE_SCHOOL_REQUEST:
      return {
        ...state,
        isSaving: true
      };
    case ActionTypes.SAVE_SCHOOL_REQUEST_SUCCESS:
      return {
        ...state,
        schools: updateSchools(state, action),
        isSaving: false
      };
    case ActionTypes.SAVE_SCHOOL_REQUEST_ERROR:
      return {
        ...state,
        isSaving: false,
        error: action.error
      };
    case ActionTypes.SCHOOL_STATS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.SCHOOL_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stats: set(state.stats, action.stats.school, action.stats)
      }
    case ActionTypes.SCHOOL_STATS_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state;
  }
};

export default schools;

const updateSchools = (state, action) => {
  if (!state.schools.find(school => school.id === action.school.id)) {
    state.schools.push(action.school);
    return state.schools;
  }
  return state.schools.map((school, index, collection) => {
    if (school.id === action.school.id) {
      school = action.school;
    }
    return school;
  });
};

export const getVisibleSchools = (state) =>
  Selectors.getVisibleSchools(state.schools.schools, state.schools.query);
