import School from "./endpoints";
import { loggedInSchool } from "../AuthenticatedUser/selectors";
import Level from '../Level/endpoints'
import { setLevels, calculateSchoolYears, getFilterQuery } from "../Stats";
import reduce from 'lodash/reduce'
import { addUser } from "../User/actions";

export const SCHOOLS_REQUEST = "SCHOOLS_REQUEST";
export const SCHOOLS_SUCCESS = "SCHOOLS_SUCCESS";
export const SCHOOLS_FAILURE = "SCHOOLS_FAILURE";
export const SET_SCHOOL_SEARCH_QUERY = "SET_SCHOOL_SEARCH_QUERY";
export const TOGGLE_SCHOOL_DIALOG = "TOGGLE_SCHOOL_DIALOG";
export const SAVE_SCHOOL_REQUEST = "SAVE_SCHOOL_REQUEST";
export const SAVE_SCHOOL_REQUEST_SUCCESS = "SAVE_SCHOOL_REQUEST_SUCCESS";
export const SAVE_SCHOOL_REQUEST_ERROR = "SAVE_SCHOOL_REQUEST_ERROR";
export const SCHOOL_STATS_REQUEST = "SCHOOL_STATS_REQUEST";
export const SCHOOL_STATS_SUCCESS = "SCHOOL_STATS_SUCCESS";
export const SCHOOL_STATS_FAILURE = "SCHOOL_STATS_FAILURE";
// actions
const schoolsRequest = () => ({
  type: SCHOOLS_REQUEST
});
const schoolsSuccess = schools => ({
  type: SCHOOLS_SUCCESS,
  schools
});
const schoolsFailure = error => ({
  type: SCHOOLS_FAILURE,
  error
});
export const setSchoolSearchQuery = query => ({
  type: SET_SCHOOL_SEARCH_QUERY,
  query
});
export const toggleSchoolDialog = (school, action) => ({
  type: TOGGLE_SCHOOL_DIALOG,
  school,
  action
});
export const saveSchoolRequest = () => ({
  type: SAVE_SCHOOL_REQUEST
});
export const saveSchoolRequestSuccess = school => ({
  type: SAVE_SCHOOL_REQUEST_SUCCESS,
  school
});
export const saveSchoolRequestError = error => ({
  type: SAVE_SCHOOL_REQUEST_ERROR,
  error
});

// http call
const fetchSchools = () => dispatch => {
  dispatch(schoolsRequest());
  return School.find()
    .then(schools => {
      dispatch(schoolsSuccess(schools))
      const users = reduce(schools, (acc, school) => {
        school.users.map(user => acc.push(user))
        return acc
      }, [])
      dispatch(addUser(users))
    })
    .catch(error => dispatch(schoolsFailure(error)));
};

// check if there are schools and not loading
const shouldFetchSchools = state => {
  const schools = state.schools;
  if (schools.schools && schools.schools.length) {
    return false
  }
  if (!schools.schools) {
    return true;
  }
  if (schools.isLoading) {
    return false;
  }
  return true;
};

// called in container?
export const fetchSchoolsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchSchools(getState())) {
    return dispatch(fetchSchools());
  }
};

export const saveSchool = school => (dispatch, getState) => {
  dispatch(saveSchoolRequest());
  const schoolUpdateOrCreate = school.id
    ? School.update(school.id, school)
    : School.create(school);
  return schoolUpdateOrCreate
    .then(school => dispatch(saveSchoolRequestSuccess(school)))
    .then(dispatch(toggleSchoolDialog()))
    .catch(error => dispatch(saveSchoolRequestError(error)));
};

const statsRequest = () => ({ type: SCHOOL_STATS_REQUEST });
const statsSuccess = stats => ({ type: SCHOOL_STATS_SUCCESS, stats })
const statsFailure = (err) => ({ type: SCHOOL_STATS_FAILURE, err })
export const fetchStats = () => async (dispatch, getState, { http }) => {
  dispatch(statsRequest())
  const school = loggedInSchool(getState())

  return Promise.all([
    School.findOne(school),
    Level.find(),
  ])
    .then(([_school, levels]) => {
      dispatch(calculateSchoolYears({ school: _school }))
      dispatch(setLevels({ levels }))
      
      const filter = getFilterQuery(getState())
      return http.get(`/school/${school}/stats`, {
        params: filter
      })
    })
    .then(res => res.data)
    .then(schoolStats => dispatch(statsSuccess({ school, schoolStats })))
    .catch(err => dispatch(statsFailure(err)))
}