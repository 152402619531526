import * as ActionTypes from './actions'
import unionBy from 'lodash/unionBy'
import * as Selectors from './selectors'

const initialState = {
    isFetching: false,
    teachers: [],
    roles: [],
    teacher: {
        name: '',
        email: '',
    },
    error: null,
    isOpen: false,
    isSaving: false,
    query: '',
    stagedForImport: [],
    isImporting: false
}

const Teacher = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.TEACHER_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case ActionTypes.TEACHER_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                teachers: action.teachers
            }
        case ActionTypes.TEACHER_REQUEST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case ActionTypes.TOGGLE_DIALOG:
            return {
                ...state,
                isOpen: !state.isOpen
            }
        case ActionTypes.ROLE_REQUEST_SUCCESS:
            return {
                ...state,
                roles: action.roles
            }
        case ActionTypes.SET_TEACHER:
            return {
                ...state,
                teacher: action.teacher
            }
        case ActionTypes.SAVE_TEACHER_REQUEST:
            return {
                ...state,
                isSaving: true
            }
        case ActionTypes.SAVE_TEACHER_REQUEST_SUCCESS:
            return {
                ...state,
                isSaving: false,
                isOpen: false,
                teachers: unionBy([action.teacher], state.teachers, 'id'),
                teacher: { name: '', email: ''},
            }
        case ActionTypes.SAVE_TEACHER_REQUEST_FAILURE:
            return {
                ...state,
                isSaving: false,
                error: action.error
            }
        case ActionTypes.SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        case ActionTypes.REMOVE_TEACHER_REQUEST_SUCCESS:
            return {
                ...state,
                teachers: state.teachers.filter(teacher => teacher.id !== action.teacher.id)
            }
        case ActionTypes.REMOVE_TEACHER_REQUEST_FAILURE:
            return {
                ...state,
                error: action.error
            }
        case ActionTypes.STAGE_FOR_IMPORT:
            return {
                ...state,
                stagedForImport: action.payload
            }
        case ActionTypes.ADD_TEACHER:
            return {
                ...state,
                teachers: unionBy([action.teacher], state.teachers, 'id')
            }
        case ActionTypes.START_IMPORT:
            return {
                ...state,
                isImporting: true
            }
        case ActionTypes.ERROR_IMPORT:
            return {
                ...state,
                isImporting: false,
                error: action.error
            }
        case ActionTypes.FINISH_IMPORT:
            return {
                ...state,
                isImporting: false,
                stagedForImport: []
            }
        default:
            return state;
    }
}

export const getVisibleTeachers = (state, query) => Selectors.getVisibleTeachers(state.Teacher.teachers, query)

export default Teacher
