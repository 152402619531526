import React, { Component } from "react";
import logoWhite from "./logo-white.svg";
import { Icon } from '@blueprintjs/core';
import auth from "../../store/auth";
import classNames from 'classnames';

class Sidenav extends Component {
  render() {
    return (
      <div id="sidenav-wrapper" className={classNames("col-xs", this.props.className)}>
        <div className={`sidenav ${this.props.isSidenavOpen ? 'open': 'close'}`}>
          <div className="sidenav-header">
            <h5 className="header-five">{this.props.schoolName || "Savvy"}</h5>
            <Icon className="show-up-to-desktop" iconName="cross" onClick={() => this.props.closeSidenav()}/>
          </div>

          <div className="list">
            <ul className="sidenav-items">
              {this.props.children}
              {this.props.isAuthenticated ? (
                <div className="sidenav-item" onClick={() => {
                  auth.logout();
                  this.props.logout()
                  this.props.history.push('/authenticate')
                }}>
                  {this.props.isLogoutDisabled 
                    ? null 
                    : <li className="body-2 logout">Uitloggen<Icon iconName="log-out"></Icon></li>}
                </div>
              ) : null}
            </ul>
          </div>
          <div className="sidenav-logo">
            <img src={logoWhite} alt="Savvy Learning logo" />
          </div>
        </div>
      </div>
    );
  }
}

export default Sidenav;
