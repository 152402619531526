import React, { Component } from 'react';
import { Dialog } from "@blueprintjs/core";
import CourseEditForm from './CourseEditForm';

export default class CourseEditDialog extends Component {
  render() {
    return (
      <Dialog
              isOpen={this.props.isOpen}
              onClose={this.props.toggleDialog}
              title={this.props.mode === 'edit' ? "Vak bewerken" : "Nieuw vak"}
          >
          <CourseEditForm {...this.props}></CourseEditForm>
      </Dialog>
    )
  }
}
