import { includes, toLower, keys } from "lodash";
import orderBy from 'lodash/orderBy'

export const getVisibleTeachers = (teachers, query) => {
    if (!query) return orderBy(teachers, 'id');
    return orderBy(teachers.filter(teacher => 
        includes(toLower(teacher.name), toLower(query)) ||
        includes(toLower(teacher.email), toLower(query))
    ), 'id')
}

export const getCSVHeaders = state => {
    const { stagedForImport } = state.Teacher
    return {
        supportedHeaders: {'email': 'E-mailadres', 'name': 'Naam'},
        headersFromImport: keys(stagedForImport[0])
    }
}

export const isStagingImport = state => !!state.Teacher.stagedForImport.length
export const isImporting = state => state.Teacher.isImporting