import React, { Component } from 'react';
import { ButtonGroup, Button, Card, Dialog, Intent } from "@blueprintjs/core";
import Avatar from '../Avatar';
import StudentClassBadge from './StudentClassBadge';

export default class TutorRequestCardItem extends Component {

  constructor(props){
    super(props);
    this.state = { isOpen: false, isLoading: false };
    this.toggleConfirmDecline = this.toggleConfirmDecline.bind(this);
  }

  toggleConfirmDecline(){
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <div className="card-wrapper">
        <Card>
          <div className="card-content">
          <div className="card-title">
            <Avatar src={this.props.tutor.user.avatarUrl} placeholder={this.props.tutor.user.name} />
            <div className="card-text">
              <p className="body-2">
                {this.props.tutor.user.name}
              </p>
              <span className="caption"><StudentClassBadge student={this.props.tutor.student} /></span>
            </div>
          </div>
          <div className="request">
            <p className="body-1">Wil toestemming om bijles {this.props.tutor.course.name} te kunnen geven.</p>
          </div>
        </div>
          <ButtonGroup fill={true}>
            <Button onClick={this.toggleConfirmDecline} className="decline card-button">Afwijzen</Button>
            <Button className="accept card-button" 
              onClick={() => {
                this.setState({ isLoading: true })
                this.props.acceptTutorRequest(this.props.tutor.id)
              }} 
              loading={(this.props.hasError) ? false : this.state.isLoading}>Goedkeuren</Button>
          </ButtonGroup>
        </Card>
        <Dialog
            iconName="alert"
            isOpen={this.state.isOpen}
            onClose={this.toggleConfirmDecline}
            title="Verzoek afwijzen"
        >
            <div className="pt-dialog-footer">
                <div className="pt-dialog-footer-actions">
                    <Button 
                      text="Bevestigen" 
                      onClick={() => {
                        this.setState({ isLoading: true })
                        this.props.denyTutorRequest(this.props.tutor.id)
                      }}
                      loading={(this.props.hasError) ? false : this.state.isLoading} />
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={this.toggleConfirmDecline}
                        text="Annuleren"
                    />
                </div>
            </div>
        </Dialog>
      </div>
    )
  }
}
