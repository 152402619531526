import token from "./token";
import decode from "jwt-decode";
import { intersection, isEmpty } from "lodash";
import currentSchool from "./currentSchool";
import School from "./School/endpoints";
import get from 'lodash/get'

const auth = {
  isAuthenticated: () => {
    const accessToken = token.get("accessToken");
    const refreshToken = token.get("refreshToken");
    if (isEmpty(accessToken) && isEmpty(refreshToken)) {
      return false;
    }

    if (decode(refreshToken).exp < Date.now() / 1000) {
      return false;
    }
    return true;
  },

  accessToken: () => {
    return token.get("accessToken");
  },

  refreshToken: () => {
    return token.get("refreshToken");
  },

  isAccessTokenExpired: () => {
    if (!auth.isAuthenticated()) return true;
    if (decode(auth.accessToken()).exp > Date.now() / 1000) {
      return false;
    }
    return true;
  },

  hasRole: (...role) => {
    if (!auth.accessToken()) return false;
    const payload = decode(auth.accessToken());
    return !!intersection(payload.roles, role).length;
  },

  authenticate: (refreshToken, accessToken) => {
    token.set("accessToken", accessToken);
    token.set("refreshToken", refreshToken);
    School.findOne(decode(accessToken).school).then(school =>
      currentSchool.set(school)
    );
  },

  decode: () => {
    try {
      return decode(token.get("accessToken"))
    } catch (e) {
      return null
    }
  },

  roles: () => get(auth.decode(), 'roles', []),

  isAdmin: () => auth.hasRole('admin'),

  logout: () => {
    token.logout();
  }
};

export default auth;
