import * as ActionTypes from "./actions";
import auth from "../auth";
import decode from "jwt-decode";
import get from 'lodash/get'

const initialState = {
  user: get(auth.decode(), 'sub', null),
  school: get(auth.decode(), 'school', null),
  accessToken: auth.accessToken(),
  refreshToken: auth.refreshToken(),
  roles: auth.roles(),
  isAuthenticated: auth.isAuthenticated(),
  isAuthenticating: false,
  isAdmin: auth.isAdmin(),
  loading: false,
  email: "",
  loggedInAsSchool: null,
  error: null,
};

const AuthenticatedUser = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE_REQUEST:
      return {
        ...state,
        email: action.email,
        loading: true,
        error: null,
      };
    case ActionTypes.AUTHENTICATE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticating: true
      };
    case ActionTypes.AUTHENTICATE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: { message: 'Er is iets fout gegaan, probeer het opnieuw' }
      }
    case ActionTypes.AUTHENTICATED:
      const decoded = decode(action.accessToken);
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        isAuthenticated: true,
        roles: decoded.roles,
        school: decoded.school,
        user: decoded.sub,
        isAdmin: !!decoded.roles.filter(r => r === 'admin').length
      };
    case ActionTypes.SET_LOGGED_IN_SCHOOL:
      return {
        ...state,
        loggedInAsSchool: action.school
      }
    case ActionTypes.LOGOUT:
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        user: null,
        school: null,
        roles: null,
        isAuthenticated: false,
        isAuthenticating: false,
        isAdmin: false,
        loggedInAsSchool: null
      }
    case ActionTypes.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ActionTypes.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state;
  }
};

export default AuthenticatedUser;
