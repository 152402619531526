import {
  unionBy,
  isArray,
  includes,
  toLower
} from 'lodash';

const initialState = {
  isFetching: false,
  tutorRequests: [],
  tutors: [],
  fetchFailure: null,
  pendingRequest: false,
  requestErr: null,
  query: null,
};

export const Tutor = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TUTOR_REQUESTS:
      return { ...state, isFetching: true, fetchFailure: null }
    case FETCH_TUTOR_REQUESTS_SUCCESS:
      return { ...state, isFetching: false, tutorRequests: unionBy(state.tutorRequests, action.tutorRequests, 'id') }
    case FETCH_TUTOR_REQUESTS_FAILURE:
      return { ...state, isFetching: false, fetchFailure: action.fetchFailure }
    case ADD_ENTITY_TUTORREQUEST:
      return { ...state, tutorRequests: unionBy((isArray(action.item)) ? action.item : [action.item], state.tutorRequests, 'id') }
    case DENY_TUTOR_REQUEST:
      return { ...state, pendingRequest: true, requestErr: null }
    case DENY_TUTOR_REQUEST_SUCCESS:
      return { ...state, pendingRequest: false, tutorRequests: state.tutorRequests.filter(req => req.id !== action.id) }
    case DENY_TUTOR_REQUEST_FAILURE:
      return { ...state, pendingRequest: false, requestErr: action.err }
    case ACCEPT_TUTOR_REQUEST:
      return { ...state, pendingRequest: true, requestErr: null }
    case ACCEPT_TUTOR_REQUEST_SUCCESS:
      return { ...state, pendingRequest: false }
    case ACCEPT_TUTOR_REQUEST_FAILURE:
      return { ...state, pendingRequest: false, requestErr: action.err }
    case ADD_ENTITY_TUTOR:
      return { ...state, tutors: unionBy((isArray(action.tutor)) ? action.tutor : [action.tutor], state.tutors, 'id') }
    case REMOVE_ENTITY_TUTOR:
      return { ...state, tutors: state.tutors.filter(tutor => tutor.id !== action.tutorId) }
    case SET_SEARCH_QUERY:
      return { ...state, query: action.query }
    default:
      return state;
  }
};
export default Tutor;

// selectors
export const selectTutors = state => {
  if (state.query) {
    return state.tutors.filter(t => includes(toLower(t.user.name), toLower(state.query)))
  }
  return state.tutors
};
export const selectTutorRequests = state => {
  const tutorRequests = state.tutorRequests.filter(item => (item.accepted) ? false : true )
  if (state.query) {
    return tutorRequests.filter(tr => includes(toLower(tr.user.name), toLower(state.query)))
  }
  return tutorRequests
};

// actions
export const FETCH_TUTOR_REQUESTS = 'FETCH_TUTOR_REQUESTS';
export const FETCH_TUTOR_REQUESTS_SUCCESS = 'FETCH_TUTOR_REQUESTS_SUCCESS';
export const FETCH_TUTOR_REQUESTS_FAILURE = 'FETCH_TUTOR_REQUESTS_FAILURE';
export const ADD_ENTITY_TUTORREQUEST = 'ADD_ENTITY_TUTORREQUEST';
export const DENY_TUTOR_REQUEST = 'DENY_TUTOR_REQUEST';
export const DENY_TUTOR_REQUEST_SUCCESS = 'DENY_TUTOR_REQUEST_SUCCESS';
export const DENY_TUTOR_REQUEST_FAILURE = 'DENY_TUTOR_REQUEST_FAILURE';
export const ACCEPT_TUTOR_REQUEST = "ACCEPT_TUTOR_REQUEST";
export const ACCEPT_TUTOR_REQUEST_SUCCESS = "ACCEPT_TUTOR_REQUEST_SUCCESS";
export const ACCEPT_TUTOR_REQUEST_FAILURE = "ACCEPT_TUTOR_REQUEST_FAILURE";
export const FETCH_ALLOWED_TUTORS = "FETCH_ALLOWED_TUTORS"; 
export const FETCH_ALLOWED_TUTORS_SUCCESS = "FETCH_ALLOWED_TUTORS_SUCCESS"; 
export const FETCH_ALLOWED_TUTORS_FAILURE = "FETCH_ALLOWED_TUTORS_FAILURE";
export const ADD_ENTITY_TUTOR = "ADD_ENTITY_TUTOR";
export const REMOVE_ENTITY_TUTOR = "REMOVE_ENTITY_TUTOR";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";

// action creators
const tutorRequest            = () => ({ type: FETCH_TUTOR_REQUESTS })
const tutorRequestSuccess     = tutorRequests => ({ tutorRequests, type: FETCH_TUTOR_REQUESTS_SUCCESS });
const tutorRequestFailure     = fetchFailure => ({ fetchFailure, type: FETCH_TUTOR_REQUESTS_FAILURE });
const addEntityTutorrequest   = item => ({ item, type: ADD_ENTITY_TUTORREQUEST });
const denyTutorRequestStart   = () => ({ type: DENY_TUTOR_REQUEST });
const denyTutorRequestSuccess = id => ({ type: DENY_TUTOR_REQUEST_SUCCESS, id });
const denyTutorRequestFailure = err => ({ type: DENY_TUTOR_REQUEST_FAILURE, err });
const acceptTutorRequestStart = () => ({ type: ACCEPT_TUTOR_REQUEST });
const acceptTutorRequestSuccess = () => ({ type: ACCEPT_TUTOR_REQUEST_SUCCESS });
const acceptTutorRequestFailure = err => ({ type: ACCEPT_TUTOR_REQUEST_FAILURE, err });
const fetchAllowedTutorsRequest = () => ({ type: FETCH_ALLOWED_TUTORS });
const fetchAllowedTutorsSuccess = payload => ({ type: FETCH_ALLOWED_TUTORS_SUCCESS });
const fetchAllowedTutorsFailure = err => ({ type: FETCH_ALLOWED_TUTORS_FAILURE, err});
const addEntityTutor = tutor => ({ type: ADD_ENTITY_TUTOR, tutor });
const removeEntityTutor = tutorId => ({ type: REMOVE_ENTITY_TUTOR, tutorId})
export const setSearchQuery = query => ({ type: SET_SEARCH_QUERY, query })

export const fetchTutorRequests = () =>  (dispatch, getState, { http }) => {
  dispatch(tutorRequest());
  http.get(`/tutorrequest`)
    .then(res => res.data)
    .then(tutorRequests => dispatch(tutorRequestSuccess(tutorRequests)))
    .catch(err => dispatch(tutorRequestFailure(err)))
}

export const denyTutorRequest = id => (dispatch, getState, { http }) => {
  dispatch(denyTutorRequestStart())
  http.delete(`/tutorrequest/${id}`)
    .then(() => dispatch(denyTutorRequestSuccess(id)))
    .catch(err => dispatch(denyTutorRequestFailure(err)))
}

export const acceptTutorRequest = id => (dispatch, getState, { http }) => {
  dispatch(acceptTutorRequestStart());
  http.put(`/tutorrequest/${id}`, { accepted: true })
    .then(res => res.data)
    .then(tutorRequest => {
      dispatch(addEntityTutorrequest(tutorRequest))
      return http.post(`/tutorship`, {
          tutor: tutorRequest.tutor.id,
          course: tutorRequest.course.id
        })
    })
    .then(res => res.data)
    .then(tutorship => dispatch(acceptTutorRequestSuccess()))
    .then(() => dispatch(fetchAllowedTutors()))
    .catch(err => dispatch(acceptTutorRequestFailure(err)))
}

export const fetchAllowedTutors = () => (dispatch, getState, { http }) => {
  dispatch(fetchAllowedTutorsRequest());
  http.get('/allowed-tutor')
    .then(res => res.data)
    .then(tutors => {
      dispatch(addEntityTutor(tutors))
      dispatch(fetchAllowedTutorsSuccess())
    })
    .catch(err => dispatch(fetchAllowedTutorsFailure(err)))
}

export const revokeTutorship = (tutor, course) => (dispatch, getState, { http }) => {
  http.delete('/tutorship', { params: { tutor, course }})
    .then(() => dispatch(removeEntityTutor(tutor)))
    .then(() => dispatch(fetchAllowedTutors()))
}
