import React from "react";

const BarGroup = ({ children, labels, className }) => (
    <div className={`percentage-bar ${className}`}>
      <div className="percentage-bar-subheaders row">
          <p className="caption percentage-bar-subheader col-xs-8">{labels[0]}</p>
          <p className="caption percentage-bar-subheader col-xs-2">{labels[1]}</p>
      </div>
      {children}
    </div>
)

export default BarGroup