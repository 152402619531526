import * as ActionTypes from './actions'
import set from 'lodash/set'
import unionBy from 'lodash/unionBy'
import * as Selectors from './selectors'

const emptyCourse = { name: '', shortName: '' }

const initialState = {
    isFetching: false,
    courses: [],
    course: emptyCourse,
    error: null,
    isOpen: false,
    mode: 'create',
    isSaving: false,
    // search
    query: '',
    // Removing a course
    isRemoveCourseAlertOpen: false,
    courseToRemove: null
}

const Course = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.COURSE_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case ActionTypes.COURSE_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                courses: action.courses
            }
        case ActionTypes.COURSE_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case ActionTypes.TOGGLE_DIALOG:
            return {
                ...state,
                isOpen: !state.isOpen,
                isSaving: false
            }
        case ActionTypes.VIEW_COURSE:
            return {
                ...state,
                course: action.course,
                isOpen: true,
                mode: 'edit'
            }
        case ActionTypes.ON_COURSE_CHANGE:
            return {
                ...state,
                course: set(state.course, action.name, action.value) 
            }
        case ActionTypes.SET_COURSE:
            return {
                ...state,
                course: action.course
            }
        case ActionTypes.SAVE_COURSE_REQUEST:
            return {
                ...state,
                isSaving: true
            }
        case ActionTypes.SAVE_COURSE_REQUEST_SUCCESS:
            return {
                ...state,
                course: emptyCourse,
                // courses: state.courses.map(course => (course.id === action.course.id) 
                //     ? action.course 
                //     : course),
                courses: unionBy([action.course], state.courses, 'id'),
                isSaving: false,
                isOpen: false
            }
        case ActionTypes.SAVE_COURSE_REQUEST_FAILURE:
            return {
                ...state,
                error: action.error
            }
        case ActionTypes.CREATE_COURSE:
            return {
                ...state,
                isOpen: true,
                course: emptyCourse,
                mode: 'create'
            }
        case ActionTypes.SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        case ActionTypes.TOGGLE_REMOVE_COURSE_ALERT:
            return {
                ...state,
                isRemoveCourseAlertOpen: !state.isRemoveCourseAlertOpen
            }
        case ActionTypes.SET_COURSE_TO_REMOVE:
            return {
                ...state,
                courseToRemove: action.course
            }
        case ActionTypes.REMOVE_COURSE_REQUEST_SUCCESS:
            return {
                ...state,
                courseToRemove: null,
                courses: state.courses.filter(course => course.id !== state.courseToRemove.id)
            }
        default:
            return state;
    }
}

export const getVisibleCourses = (state, query) => Selectors.getVisibleCourses(state.Course.courses, query)

export default Course
