import http from '../store/http'

const drift = window.drift

export const page = (args) => {
    if (!drift) {
        return
    }

    return drift.page(args)
}

export const identity = () => {
    if (!drift) {
        return
    }

    return http.get(`/user/me`)
          .then(res => res.data)
          .then(user => {
            return drift.identify(user.id, {
              name: user.name,
              email: user.email,
              school: user.school.name,
              avatar_url: user.avatarUrl
            })
          })
          .catch(err => console.log('could not identify with drift', err))
}