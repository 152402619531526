import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PaymentsNavbar from "../components/Payments/PaymentsNavbar";
import PaymentsList from "../components/Payments/PaymentsList";
import {
  Spinner,
  NonIdealState,
  Toaster,
  Position,
  Intent
} from "@blueprintjs/core";

import {
  selectPayments,
  fetchPayments,
  payoutPayment
} from '../store/Payment';
import { openSidenav } from "../store/App/actions";

class PaymentsContainer extends Component {
  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(e) {
    const query = e.target.value;
    this.props.setPaymentsSearchQuery(query);
  }

  componentDidMount() {
    this.props.fetchPayments();
  }

  componentDidUpdate() {
    const { hasError } = this.props;
    if (!hasError) return null;
    
    let message = '';
      
    switch(hasError.message) {
      default:
        message = 'Er ging iets fout probeer het opnieuw'
        break;
    }

    this.toaster.show({
      intent: Intent.DANGER,
      message: message,
      iconName: 'warning'
    })
  }

  render() {
    return (
      <div>
        <PaymentsNavbar {...this.props} onSearch={this.onSearch} />
        {this.props.isLoading 
          ? <NonIdealState visual={<Spinner />} />
          : <PaymentsList {...this.props} loginAs={this.loginAs} />
        }
        <Toaster position={Position.TOP_RIGHT} ref={ref => this.toaster = ref} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { Payment } = state;

  return {
    isLoading: Payment.isFetching,
    payments: selectPayments(Payment),
    hasError: Payment.requestErr,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchPayments: () => dispatch(fetchPayments()),
  payoutPayment: payment => dispatch(payoutPayment(payment)),
  openSidenav: () => dispatch(openSidenav())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentsContainer));
