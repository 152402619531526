import React, { Component } from 'react';
import { Popover, MenuItem, Position, Menu, Button, Intent } from "@blueprintjs/core";

export default class CourseListItem extends Component {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
        this.onEdit = this.onEdit.bind(this)
    }

    onClick(e) {
        this.props.onClick(this.props.course)
    }

    onEdit(e) {
        this.props.onEdit(e, this.props.course);
    }

    render() {
        const {course} = this.props
        return (
        <div className="list-item row middle-xs">
            <p className="col-xs body-2">({course.shortName}) {course.name}</p>
            <Popover className="col-xs list-item-action" position={Position.LEFT_TOP} content={<Menu>
                <MenuItem
                    onClick={this.onClick}
                    text="Bekijken"
                />
                <MenuItem
                    onClick={this.onEdit}
                    text="Aanpassen"
                />
                <MenuItem
                    intent={Intent.DANGER}
                    onClick={(e) => this.props.toggleRemoveCourseAlert(e, course)}
                    text="Verwijderen"
                />
            </Menu>} target={<Button iconName="more" />} />
        </div>
        )
    }
}
