import React, { Component } from "react";
import Graph from "./Graph";
import Heatmap from "./Heatmap/Heatmap";

export default class SignupsHeatmap extends Component {
  render() {
    const { labels, dataset } = this.props

    return (
        <Graph title="Aanmeldingen per niveau en leerjaar" description="Bij aanmeldingen kan je zien in welke leerjaren er nog extra aandacht nodig is om leerlingen aan de slag te laten gaan met Savvy.">
            <Heatmap labels={labels} dataset={dataset} />
        </Graph>
    )
  }
}