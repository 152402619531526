import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import qs from 'query-string';
import decode from 'jwt-decode';
import moment from 'moment';

import { Callout, Intent } from '@blueprintjs/core';

import ForgotPasswordForm from "../components/ForgotPassword/ForgotPasswordForm";
import AuthenticateForm from "../components/Authenticate/AuthenticateForm";
import { disableSidenav } from "../store/App/actions";
import { resetPasswordWithToken, setPassword, setPasswordRepeat, setToken } from "../store/PasswordReset/actions";

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { passwordResetToken: qs.parse(this.props.location.search).token };
    
    if (!this.state.passwordResetToken || !this.state.passwordResetToken.length) {
      this.state.hasError = {
        message: 'Een uniek token ontbreekt om je wachtwoord opnieuw in te kunnen stellen. Controleer de url of probeer de link uit het mailtje opnieuw te openen'
      }
    }

    if ( this.state.passwordResetToken && this.isTokenExpired(this.state.passwordResetToken) ) {
      this.state.hasError = {
        message: 'Dit token is verlopen'
      }
    }
  }

  isTokenExpired(token) {
    const decoded = decode(token)
    return moment(decoded.exp).isBefore(moment().unix())
  }

  componentDidMount() {
    this.props.disableSidenav()
    this.props.setToken(this.state.passwordResetToken)
  }

  render() {
    if (this.state.hasError) {
      return (
        <AuthenticateForm>
          <Callout title="Oeps!" intent={Intent.DANGER}>
            {this.state.hasError.message}
          </Callout>
        </AuthenticateForm>
      )
    }

    return (
      <ForgotPasswordForm {...this.props}></ForgotPasswordForm>
    );
  }
}

const mapStateToProps = state => {
  const { PasswordReset } = state;
  return PasswordReset;
};

const mapDispatchToProps = dispatch => ({
  disableSidenav: () => dispatch(disableSidenav()),
  setToken: token => dispatch(setToken(token)),
  onChange: state => {
    dispatch(setPassword(state.newPassword))
    dispatch(setPasswordRepeat(state.passwordRepeat))
  },
  onSubmit: (e) => {
    e.preventDefault()
    dispatch(resetPasswordWithToken())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer));
