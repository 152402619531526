import * as ActionTypes from "./actions";

const plans = (
  state = {
    isFetching: false,
    plans: [],
    error: null
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.PLANS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ActionTypes.PLANS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        plans: action.plans
      };
    case ActionTypes.PLANS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default plans;
