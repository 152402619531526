import React, { Component } from 'react';
import { Popover, Position, Button, Menu, MenuItem } from "@blueprintjs/core";

export default class PaymentsNavbar extends Component {
  render() {
    return (
      <nav className="pt-navbar row middle-xs">
        <div className="pt-navbar-group col-xs">
          <button className="pt-button pt-icon-menu pt-large show-up-to-desktop" onClick={() => this.props.openSidenav()}></button>
          <h3 className="pt-navbar-heading hide-up-to-tablet-portrait">Uitbetalingen</h3>
          <input className="pt-input pt-fill" placeholder="Zoeken tussen betalingen..." type="text"  disabled={true} onChange={this.props.onSearch} />
          <Popover
            isDisabled={true}
            className="col-xs list-item-action"
            position={Position.LEFT_TOP}
            content={
              <Menu>
                <MenuItem
                  onClick="void;"
                  text="Alles tonen"
                />
                <MenuItem
                  onClick="void;"
                  text="Alleen open"
                />
                <MenuItem
                  onClick="void;"
                  text="Alleen verwerkt"
                />
              </Menu>
            }
            target={<Button className="pt-button pt-intent-success pt-icon-filter-list pt-large" id={"popOverClick"} iconName="filter" />}
          />
        </div>
      </nav>
    );
  }
}
