export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
const resetPasswordRequest = () => ({
    type: RESET_PASSWORD_REQUEST
})

export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
const resetPasswordRequestSuccess = () => ({
    type: RESET_PASSWORD_REQUEST_SUCCESS
})

export const RESET_PASSWORD_REQUEST_FAILURE = "RESET_PASSWORD_REQUEST_FAILURE";
const resetPasswordRequestFailure = error => ({
    type: RESET_PASSWORD_REQUEST_FAILURE,
    error
})

export const resetPasswordWithToken = () => (dispatch, getState, { http }) => {
    const state = getState().PasswordReset;
    dispatch(resetPasswordRequest())
    return http.post('/auth/resetPassword', {
        passwordResetToken: state.token,
        newPassword: state.password,
        passwordRepeat: state.passwordRepeat
    })
        .then(res => res.data)
        .then(data => dispatch(resetPasswordRequestSuccess()))
        .catch(err => dispatch(resetPasswordRequestFailure(err)))
}

export const SET_TOKEN = "SET_TOKEN";
export const setToken = token => ({
    type: SET_TOKEN,
    token
})

export const SET_PASSWORD = "SET_PASSWORD";
export const setPassword = password => ({
    type: SET_PASSWORD,
    password
})

export const SET_PASSWORD_REPEAT = "SET_PASSWORD_REPEAT";
export const setPasswordRepeat = passwordRepeat => ({
    type: SET_PASSWORD_REPEAT,
    passwordRepeat
})