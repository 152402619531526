import React, { Component } from 'react';
import {
  NonIdealState
} from "@blueprintjs/core";
import PaymentsListItem from './PaymentsListItem';

export default class PaymentsList extends Component {
  render() {
    return (
      <div>
      {!this.props.payments.length 
        ? (<NonIdealState
            title="Geen betalingen gevonden"
            description="Bien trabajo hermano! 👨🏽"
          />)
        : null
      }
        <div className="list">
          {this.props.payments.map(payment => <PaymentsListItem key={payment.id} payment={payment} onPayout={payment => this.props.payoutPayment(payment)} />)}
        </div>
      </div>
    );
  }
}
