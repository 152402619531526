import React, { Component } from 'react';
import { NonIdealState, Intent, Button, Spinner, Alert } from "@blueprintjs/core";
import CourseListItem from './CourseListItem';
import get from 'lodash/get';

export default class CourseList extends Component {
  render() {
    if (this.props.isFetching) {
      return (<NonIdealState action={<Spinner />} />)
    }

    if (!this.props.courses.length) {
      return (<NonIdealState
        title="Geen vakken"
        description={`Er zijn geen vakken beschikbaar voor de ${get(this.props.school, 'name', '')}. Voeg er een toe!`}
        action={
          <Button
            intent={Intent.PRIMARY}
            onClick={this.props.toggleDialog}
            className="pt-large"
            text="Vak toevoegen" />
        }>
      </NonIdealState>)
    }

    return (
      <div>
        <div className="list">
          {this.props.courses.map(course => (<CourseListItem {...this.props} key={course.id} course={course} />))}
        </div>
        <Alert
          intent={Intent.DANGER}
          isOpen={this.props.isRemoveCourseAlertOpen}
          confirmButtonText="Verwijderen"
          cancelButtonText="Annuleren"
          onConfirm={this.props.handleRemoveCourse}
          onCancel={this.props.toggleRemoveCourseAlert}>
          <p>Weet je zeker dat je het vak <b>{this.props.courseToRemove && this.props.courseToRemove.name}</b> wilt verwijderen?</p>
        </Alert>
      </div>
    )
  }
}
