import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Sidenav from "../components/Sidenav";
import { closeSidenav } from "../store/App/actions";
import { logout } from "../store/AuthenticatedUser/actions";

const SidenavContainer = props => <Sidenav className={{'hide': !props.isVisible}} {...props} />;

const mapStateToProps = state => ({
  schoolName: state.AuthenticatedUser.loggedInAsSchool && state.AuthenticatedUser.loggedInAsSchool.name,
  isVisible: state.App.isSidenavVisible,
  isAuthenticated: state.AuthenticatedUser.isAuthenticated,
  isSidenavOpen: state.App.isSidenavOpen,
  isLogoutDisabled: state.App.isLogoutDisabled
}) 

const mapDispatchToProps = dispatch => ({
  closeSidenav: () => dispatch(closeSidenav()),
  logout: () => dispatch(logout())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidenavContainer));
