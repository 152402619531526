import React, { Component } from "react";
import { NavLink } from 'react-router-dom'

export default class LevelsNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            ...this.props
        };
    }

    onChange(e) {
        this.setState(
            { query: e.target.value },
            () => this.props.onSearch(this.state.query)
        );
    }

    render() {
        return (
            <nav className="pt-navbar row middle-xs">
                <div className="pt-navbar-group col-xs">
                    <button
                        className="pt-button pt-icon-menu pt-large show-up-to-desktop"
                        onClick={() => this.props.openSidenav()}
                    />
                    <h3 className="pt-navbar-heading hide-up-to-tablet-portrait">
                        {this.props.title}
                    </h3>

                    {this.props.hasSearch === false
                        ? null
                        : <input
                            className="pt-input pt-fill"
                            placeholder="Zoek tussen leerniveau's..."
                            type="text"
                            onChange={e => this.onChange(e)}
                        />}
                    
                    {this.props.hasActions === false
                        ? null
                        : <NavLink
                            to="/levels/create"
                            className="pt-button pt-intent-success pt-icon-plus pt-large" />}
                    
                    {this.props.children}
                </div>
            </nav>
        );
    }
}
