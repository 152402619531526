import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TeacherNavbar from '../components/Teachers/TeacherNavbar';
import TeacherList from '../components/Teachers/TeacherList';
import TeacherEditDialog from "../components/Teachers/TeacherEditDialog";
import TeacherImport from "../components/Teachers/TeacherImport";
import TeacherMapImport from "../components/Teachers/TeacherMapImport";
import { fetchTeachers, toggleDialog, fetchRoles, setTeacher, saveTeacher, setQuery, removeTeacher, onBulkImport, startImportWithMap } from '../store/Teacher/actions';
import { getVisibleTeachers } from '../store/Teacher/reducers';
import { openSidenav } from '../store/App/actions';
import { getCSVHeaders, isStagingImport, isImporting } from '../store/Teacher/selectors';
import { Route } from 'react-router-dom'
import { isSchoolAdmin } from '../store/AuthenticatedUser/selectors';
import { setRoles } from '../store/User/actions';

class TeacherContainer extends Component {
    componentDidMount() {
        this.props.fetchTeachers()
        this.props.fetchRoles()
    }

    render() {
        return (
            <React.Fragment>
              <Route path="/teachers" exact render={() => (
                <React.Fragment>
                  <TeacherNavbar {...this.props} />
                  <TeacherEditDialog {...this.props} />
                  <TeacherList {...this.props} />
                </React.Fragment>
              )} />
              <Route path="/teachers/import" exact render={() => (
              <React.Fragment>
                  <TeacherNavbar {...this.props} />
                  <TeacherImport {...this.props} />
                  <TeacherMapImport {...this.props} />
                </React.Fragment>
              )} />
              
            </React.Fragment>
          );
    }
}

const mapStateToProps = state => {
    const { Teacher } = state;
    return {
      ...Teacher,
      teachers: getVisibleTeachers(state, Teacher.query),
      schoolName: state.AuthenticatedUser.loggedInAsSchool && state.AuthenticatedUser.loggedInAsSchool.name,
      csvHeaders: getCSVHeaders(state),
      isStagingImport: isStagingImport(state),
      isImporting: isImporting(state),
      isSchoolAdmin: isSchoolAdmin(state)
    }
}

export default withRouter(connect(mapStateToProps, {
    fetchTeachers: fetchTeachers,
    fetchRoles: fetchRoles,
    toggleDialog: toggleDialog,
    onChange: setTeacher,
    onSubmit: saveTeacher,
    onSearch: setQuery,
    removeTeacher: removeTeacher,
    openSidenav: openSidenav,
    onFileUpload: onBulkImport,
    startImportWithMap: startImportWithMap,
    setRoles: setRoles
})(TeacherContainer))