import React from 'react'

const Graph = ({ title, description, children }) => (
    <div className="graph">
        <div className="graph-inner-wrapper">
            <h5 className="graph-title">
                {title}
            </h5>
            <p className="graph-explain-content body-2">
                {description}
            </p>
            <div className="divider"></div>
            {children}
        </div>
    </div>
)

export default Graph