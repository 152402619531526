import { includes, toLower, find } from "lodash";

export const getVisibleLevels = (levels, query) => {
    if (!query) return levels;
    return levels.filter(level => includes(toLower(level.name), toLower(query)))
}

export const getLevel = (state, id) => {
    return find(state.Level.levels, { id: parseInt(id, 10) })
}

export const isFetching = (state) => state.Level.isFetching
export const isSaving = (state) => state.Level.isSaving
export const getLevels = (state) => state.Level.levels