import React, { Component } from "react";
import { Button, Tooltip, Position } from "@blueprintjs/core";
import moment from "moment";
import "moment/locale/nl";
import get from "lodash/get";
import { withRouter } from "react-router-dom";

class SchoolListItem extends Component {
  openDetailPage(e) {
    if (e.target.id !== 'popOverClick') {
      this.props.history.push(`/schools/${this.props.school.id}`)
    }
  }

  render() {
    return (
      <div
        className="list-item row middle-xs"
        onClick={(e) => this.openDetailPage(e)}
      >
        <div className="two-line col-xs">
          <p className="body-2">{this.props.school.name}</p>
          <p>{moment(this.props.school.createdAt).format("D MMM YYYY")}</p>
        </div>
        <p className="col-xs hide-up-to-desktop">
          {get(this.props, "school.contact.name", "Geen contactpersoon")}
        </p>
        <p className="col-xs hide-up-to-tablet-portrait">
          {get(this.props, "school.contact.phone", "Geen telefoonnummer")}
        </p>
        <p className="col-xs hide-up-to-desktop">
          {get(this.props, "school.address.city", "Geen stad")}
        </p>
        <Tooltip content="Login als school" position={Position.LEFT}>
          <Button id={"popOverClick"} iconName="key" onClick={() => this.props.loginAsSchool(this.props.school.id)} />
        </Tooltip>
      </div>
    );
  }
}

export default withRouter(SchoolListItem)