import React, { Component } from 'react';
import { Popover, MenuItem, Position, Menu, Button, Intent } from "@blueprintjs/core";
import Avatar from '../Avatar';

const RoleSwitcher = ({ isSchoolAdmin, roles, user, setRoles }) => {
  if (!isSchoolAdmin || !roles) {
    return null
  }

  roles = roles.filter(role => role.id !== 3)

  return (
    <Popover className="list-item-popover" content={
      <Menu>
        {roles.map(role => (
          <MenuItem
            key={role.id}
            onClick={() => setRoles(user, roles.filter(r => r.rank >= role.rank))}
            text={role.name}
          />
        ))}
      </Menu>
      } position={Position.BOTTOM_LEFT}>
      <Button 
        text={user.roles
          .sort((a,b) => a.rank > b.rank)
          .map(role => role.name)
          .join(', ')} 
        rightIconName="pt-icon-chevron-down" 
        className="pt-minimal" />
    </Popover>
  )
}

export default class TeacherListItem extends Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this)
  }

  onDelete(e) {
    return this.props.removeTeacher(this.props.teacher)
  }

  render() {
    return (
      <div className="list-item row middle-xs">
        <Avatar src={this.props.teacher.avatarUrl} placeholder={this.props.teacher.name} />
        <div className="two-line col-xs">      
          <p className="body-2">{this.props.teacher.name}</p>
          <RoleSwitcher {...this.props} user={this.props.teacher} />
        </div>
        <Popover className="col-xs list-item-action" position={Position.LEFT_TOP} content={<Menu>
              <MenuItem
                  intent={Intent.DANGER}
                  onClick={this.onDelete}
                  text="Verwijderen"
              />
          </Menu>} target={<Button iconName="more" />} />
      </div>
    )
  }
}
