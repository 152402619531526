import React from "react";
import PercentageFiller from './PercentageFillerComponent';

const Bar = ({ name, count, total, percentage }) => {
    const _percentage = percentage || (count / (total / 100))
  
    return (
      <div className="percentage-bar-item row">
        <p className="caption percentage-bar-content col-xs-8">{name}</p>
        <p className="caption percentage-bar-content col-xs-2">{count}</p>
        <PercentageFiller percentage={_percentage} />
      </div>
    )
}

export default Bar