import React from "react";
import * as Router from "react-router-dom";
import intersection from "lodash/intersection";
import { connect } from 'react-redux';
import { closeSidenav } from '../../store/App/actions'

const NavLink = props => {
  // TODO: Find solution for this logic
  const { roles, hasRole, closeSidenav, ...rest } = props;

  if (roles && hasRole && !!intersection(roles, hasRole).length === false) {
    // Don't render because not the correct roles
    return null;
  }

  return (
    <Router.NavLink {...rest} className="sidenav-item">
      <li className="body-2" onClick={() => closeSidenav()}>{props.children}</li>
    </Router.NavLink>
  );
};

export default connect(null, (dispatch) => ({
  closeSidenav: () => dispatch(closeSidenav())
}))(NavLink);
