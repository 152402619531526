import React, { Component } from 'react';
import { Intent, Button, Checkbox, Popover, Position, Icon, FormGroup } from "@blueprintjs/core";
import find from 'lodash/find';
import set from 'lodash/set';

class LevelsEditForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            years: [{
                year: 1,
                checked: false,
            }, {
                year: 2,
                checked: false,
            }, {
                year: 3,
                checked: false,
            }, {
                year: 4,
                checked: false,
            }, {
                year: 5,
                checked: false,
            }, {
                year: 6,
                checked: false,
            }],
            level: {
                difficulty: -1,
                ...this.props.level
            }
        }

        this.setCheckedYears()
    }

    setCheckedYears() {
        this.setState({
            years: this.state.years.map(year => {
                if ( find(this.state.level.years, { year: year.year}) ) {
                    year.checked = true
                }
                return year
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.level.years !== this.props.level.years) {
            this.setState({
                level: {
                    ...this.state.level,
                    ...this.props.level
                }
            }, () => this.setCheckedYears())
        }
    }

    onChange(e) {
        e.preventDefault()
        this.setState(set(this.state, e.target.name, e.target.value));
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.onSubmit(this.state)
    }

    toggleYear(year) {
        const years = this.state.years.map(_year => {
            if (_year.year === year) {
                _year.checked = !_year.checked
            }
            return _year
        })

        this.setState({ years })
    }

    render() {
        const { level, years } = this.state;

        return (
            <form onSubmit={(e) => this.onSubmit(e)}>
                <FormGroup className="form" required={true}>
                    <label htmlFor="name" className="body-2">Geef het leerniveau een naam</label>
                    <Popover
                        className="schoolcode-help"
                        position={Position.LEFT_TOP}
                        content={
                            <div className="schoolcode-explain">
                                <p>
                                    Als je het leerniveau geen naam geeft krijgt het vanzelf de naam van het hieronder gekozen niveau
                                </p>
                            </div>
                        }
                        target={<Icon iconName="pt-icon-help" />}
                    />
                    <input 
                        className="pt-input pt-fill" 
                        type="text"
                        placeholder="Leerniveau naam"
                        dir="auto"
                        value={level.name}
                        onChange={(e) => this.onChange(e)}
                        name="level.name"
                        id="name"
                        tabIndex="1"
                        autoFocus
                    />
                    
                    <label htmlFor="difficulty" className="body-2">Kies een niveau</label>
                    <div className="pt-select pt-fill">
                        <select 
                            id="difficulty"
                            name="level.difficulty"
                            value={level.difficulty}
                            tabIndex="2"
                            onChange={e => this.onChange(e)}>
                            <option value="-1" disabled>Kies een niveau</option>
                            <option value="6">VWO</option>
                            <option value="5">HAVO</option>
                            <option value="4">MAVO</option>
                            <option value="3">VMBO-GL</option>
                            <option value="2">VMBO-K</option>
                            <option value="1">VMBO-B</option>
                        </select>
                    </div>

                    <label className="body-2">Selecteer leerjaren voor het leerniveau</label>
                    {years.map((year, i) =>
                        <Checkbox
                            key={i}
                            tabIndex={4 + i}
                            className="pt-large"
                            checked={year.checked}
                            onChange={() => this.toggleYear(year.year)}
                            label={`Leerjaar ${year.year}`} />
                    )}

                    <Button
                        tabIndex="10"
                        intent={Intent.SUCCESS}
                        type="submit"
                        text="Opslaan"
                        loading={this.props.isSaving}
                    />
                </FormGroup>
            </form>
        )
    }
}

LevelsEditForm.defaultProps = {
    level: {
        name: '',
        difficulty: -1,
        years: [],
    }
}

export default LevelsEditForm