import { addTeacher } from "../Teacher/actions";
import isArray from 'lodash/isArray'

export const ADD_USER = "ADD_USER";
export const addUser = user => ({
  type: ADD_USER,
  users: isArray(user) ? user : [user]
});

export const LOGIN_WITH_EMAIL = "LOGIN_WITH_EMAIL";
export const setUserEmail = email => ({
  type: LOGIN_WITH_EMAIL,
  email
});

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
const resetPasswordRequest = () => ({
  type: RESET_PASSWORD_REQUEST
})

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const resetPasswordSuccess = data => ({
  type: RESET_PASSWORD_SUCCESS,
  data
})

export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
const resetPasswordFailure = error => ({
  type: RESET_PASSWORD_FAILURE,
  error
});

export const resetPassword = email => (dispatch, getState, { http }) => {
  dispatch(resetPasswordRequest())
  http.post('/auth/forgotPassword', { email })
    .then(data => dispatch(resetPasswordSuccess(data)))
    .catch(error => dispatch(resetPasswordFailure(error.response.data)))
}

export const setRoles = (user, roles) => (dispatch, getState, { http }) => {
  http.post(`/user/${user.id}/roles`, roles)
    .then(res => {
      user.roles = res.data
      dispatch(addTeacher(user))
    })
    .catch(console.error)
}

export const fetchUser = (id) => (dispatch, getState, { http }) => {
  http.get(`/user/${id}`)
    .then(res => res.data)
    .then(user => dispatch(addUser(user)))
    .catch(console.error)
}

export const deleteUser = (user) => (dispatch, getState, { http }) => {
  http.delete(`/user/${user.id}`)
    .then(() => dispatch(addUser({
      ...user,
      deletedAt: new Date()
    })))
    .catch(console.error)
}

export const restoreUser = (user) => (dispatch, getState, { http }) => {
  http.put(`/user/${user.id}`, {
    deletedAt: null
  })
    .then(() => {
      dispatch(addUser({
        ...user,
        deletedAt: null
      }))
    })
    .catch(console.error)
}