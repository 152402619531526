import React, { Component } from 'react';
import logoBlue from './logo-blue.svg';

class AuthenticateForm extends Component {
  render() {
    return (
      <div className="authenticate">
        <img className="logo" src={logoBlue} alt="Savvy Learning logo"/>
        <form className="authentication-form" onSubmit={this.props.onSubmit}>
          {this.props.children}
        </form>
      </div>
    );
  }
}

export default AuthenticateForm;