import React from 'react'
import Graph from './Graph';
import { Line } from 'react-chartjs-2';
import { reduce, defaults } from 'lodash'

const chartOptions = { 
    elements: {
        line: {
          tension: .25
        },
        point: {
          radius: 0
        },
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 12,
        padding: 20,
      }
    },
    title: {
      display: false,
    },
    layout: {
      // padding: 25
    },
    scales: {
      yAxes: [{
        position: 'right',
        ticks: {
          padding: 10,
          beginAtZero: true,
          maxTicksLimit: 5,
          fontFamily: 'futura-pt',
          fontColor: '#000',
          fontSize: 12,
          callback: function (value, index, values) {
            if (Math.floor(value) === value) {
              return value;
            }
          }
        },
        gridLines: {
          drawBorder: false,
          color: '#E0E0E0',
          borderDash: [3, 3],
          zeroLineColor: '#E0E0E0',
          tickMarkLength: 0,
        }
      }],
      xAxes: [{
        barThickness: 15,
        gridLines: {
          drawBorder: false,
          color: '#E0E0E0',
          borderDash: [3, 3],
          zeroLineColor: '#E0E0E0',
          tickMarkLength: 10,
        },
        ticks: {
          fontFamily: 'futura-pt',
          fontColor: '#000',
          fontSize: 12
        },
      }]
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      displayColors: true,
      titleFontFamily: 'futura-pt',
      bodyFontFamily: 'futura-pt'
    }
  }
  
  const colors = [
    "#00BFFF",
    "#9800FF",
    "#FF0707"
  ]

const graphData = (labels, datasets) => ({
    labels,
    datasets: reduce(datasets, (acc, dataset, index) => {
        dataset = defaults(dataset, {
            fill: false,
            borderColor: colors[index],
            backgroundColor: colors[index],
            borderWidth: 0,
            pointBackgroundColor: colors[index],
            pointRadius: 4,
        })

        acc.push(dataset)

        return acc;
    }, [])
})

const QuestionsTutoringDayChart = ({ labels, dataset }) => (
    <Graph title="Gebruik door de week" description="In gebruik door de week zie je op welke dagen leerlingen gebruik maken van hulpvragen en bijles.">
        <div className="graph-inner-wrapper-chart-js">
            <Line data={() => graphData(labels, dataset)}
                width={100}
                height={60}
                options={chartOptions}
            />
        </div>
    </Graph>
)

export default QuestionsTutoringDayChart