import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LevelsList from "../../components/Levels/LevelsList";
import { getVisibleLevels } from '../../store/Level/reducers';
import {
    fetchLevels,
    setLevel, 
    toggleRemoveLevelAlert,
    deleteLevel 
} from '../../store/Level/actions';

class LevelListContainer extends Component {
    componentDidMount() {
        this.props.fetchLevels()
    }

    render() {
        return (<LevelsList {...this.props} />);
    }
}

const mapStateToProps = state => ({
    ...state.Level,
    levels: getVisibleLevels(state, state.Level.query)
})
const mapDispatchToProps = dispatch => ({
    fetchLevels: () => dispatch(fetchLevels()),
    onDelete: (level) => {
        dispatch(deleteLevel(level))
    },
    toggleRemoveLevelAlert: (level) => {
        dispatch(setLevel(level))
        dispatch(toggleRemoveLevelAlert())
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LevelListContainer))