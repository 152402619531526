import React, { Component } from "react";
import BarGroup from "./StatsPercentageBar/BarGroup";
import Bar from "./StatsPercentageBar/Bar";
import { NonIdealState } from "@blueprintjs/core";
import sumBy from 'lodash/sumBy'

export default class AvailableTutorsChart extends Component {
    render() {
        const { tutors, tutoring } = this.props
        
        return (
            <div className="graph">
                <div className="graph-inner-wrapper">
                <h5 className="graph-title">Tutors &amp; bijlessen</h5>
                <p className="graph-explain-content body-2">
                    Het aantal tutors dat beschikbaar is per vak en het aantal bijlessen dat wordt gevolgd. Probeer voor alle vakken minimaal twee tutoren te vinden.
                </p>
                <div className="divider"></div>
                <div className="group-graphs row">
                    {this.renderTutorsChart(tutors)}
                    {this.renderTutoringChart(tutoring)}
                </div>
                </div>
            </div>
        );
    }

    renderTutorsChart(tutors) {
        if (!tutors || !tutors.length) {
            return <NonIdealState description="Geen tutor data beschikbaar" visual="horizontal-bar-chart-desc" />
        }

        const totalTutors = sumBy(tutors, 'tutors')
        return (
            <BarGroup
                className="col-xs-12 col-md-6"
                labels={["Vak", "tutors"]}
            >
                {tutors.map((item, i) => <Bar key={i} name={item.course} count={item.tutors} total={totalTutors} />)}
            </BarGroup>
        )
    }

    renderTutoringChart(tutoring) {
        if (!tutoring || !tutoring.length) {
            return <NonIdealState description="Geen bijles data beschikbaar" visual="horizontal-bar-chart-desc" />
        }

        const totalTutoring = sumBy(tutoring, 'tutoring')
        return (
            <BarGroup
                className="col-xs-12 col-md-6"
                labels={["Vak", "Leerlingen met bijles"]}
            >
                {tutoring.map((item, i) => <Bar key={i} name={item.course} count={item.tutoring} total={totalTutoring} />)}
            </BarGroup>
        )
    }
}
