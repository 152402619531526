import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import AuthenticateEmailForm from "../components/Authenticate/AuthenticateEmailForm";
import Authenticating from "../components/Authenticate/Authenticating";
import qs from 'query-string';
import { Toaster, Position } from '@blueprintjs/core'

import {
  subscribeToAuthenticationComplete,
  authenticate,
  loginWithAccessToken
} from "../store/AuthenticatedUser/actions";
import { disableSidenav, disableLogout } from "../store/App/actions";
import AuthenticateForm from "../components/Authenticate/AuthenticateForm";
import { NonIdealState, Spinner, Callout, Intent } from "@blueprintjs/core";

class AuthenticateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "" };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const params = qs.parse(this.props.location.search);
    dispatch(disableSidenav());
    if (params.isMobileApp) {
      // dispatch hideLogoutLink
      dispatch(disableLogout())
    }
    if (params.accessToken && params.accessToken.length > 5) {
      return dispatch(loginWithAccessToken(params.accessToken));
    }
    dispatch(subscribeToAuthenticationComplete());
  }

  componentDidUpdate() {
    const { hasError } = this.props;
    if (!hasError) return null;
    
    let message = '';
    
    switch(hasError) {
      case 'E_NO_TOKEN_PROVIDED':
        message = 'Geen geldige login link gebruikt'
        break;
      case 'E_SESSION_NOT_FOUND':
      case 'E_SESSION_ALREADY_USED':
      case 'E_SESSION_EXPIRED':
        message = 'Deze login link was al gebruikt of is verlopen. Vraag een nieuwe sessie aan.'
        break;
      case 'E_SERVER_ERROR':
      default:
        message = 'Er ging iets fout probeer het opnieuw'
        break;
    }

    this.toaster.show({
      intent: Intent.DANGER,
      message: message,
      iconName: 'warning'
    })
  }

  authenticate = event => {
    event.preventDefault();
    const { dispatch } = this.props;
    const { email } = this.state;
    dispatch(authenticate(email));
  };

  handleEmailFormChange = e => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { isAuthenticated, isAuthenticating, loading, location } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    if (isAuthenticated) {
      const params = qs.parse(location.search)
      if (params.redirect) {
        return <Redirect to={decodeURI(params.redirect)} />;  
      }

      return <Redirect to={from} />;
    } else if (isAuthenticating) {
      return <Authenticating {...this.props} onSubmit={this.authenticate} />;
    } else if (loading) {
      return (
        <AuthenticateForm>
          <h4>Authenticeren...</h4>
          {!this.props.error ? null : (<Callout title="Oeps" intent={Intent.DANGER}>{this.props.error.message}</Callout>)}
          <NonIdealState visual={<Spinner />} />
        </AuthenticateForm>
      )
    }

    return (
      <div>
        <AuthenticateEmailForm
          {...this.props}
          onSubmit={this.authenticate}
          onChange={this.handleEmailFormChange}
        />
        <Toaster position={Position.TOP} ref={ref => this.toaster = ref} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { AuthenticatedUser } = state;

  const queryParams = qs.parse(ownProps.location.search)
  const error = queryParams.error || null

  return {
    ...AuthenticatedUser,
    hasError: error
  };
};

export default connect(mapStateToProps)(AuthenticateContainer);
