import { connect } from 'react-redux';
import {
  selectTutorRequests,
  selectTutors,
  fetchTutorRequests,
  denyTutorRequest,
  acceptTutorRequest,
  fetchAllowedTutors,
  revokeTutorship,
  setSearchQuery
} from '../../store/Tutor';
import { openSidenav } from '../../store/App/actions';

const mapStateToProps = state => ({
  isFetching: state.Tutor.isFetching,
  schoolName: 'Savvy',
  tutorRequests: selectTutorRequests(state.Tutor),
  tutors: selectTutors(state.Tutor),
  hasError: state.Tutor.requestErr
})

const mapDispatchToProps = dispatch => ({
  fetchTutorRequests: () => dispatch(fetchTutorRequests()),
  acceptTutorRequest: tutorRequestId => dispatch(acceptTutorRequest(tutorRequestId)),
  denyTutorRequest: tutorRequestId => dispatch(denyTutorRequest(tutorRequestId)),
  fetchTutors: () => dispatch(fetchAllowedTutors()),
  onSearch: query => dispatch(setSearchQuery(query)),
  revokeTutorship: (tutor, course) => dispatch(revokeTutorship(tutor, course)),
  openSidenav: () => dispatch(openSidenav()),
})

export default connect(mapStateToProps, mapDispatchToProps)