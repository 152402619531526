import React, { Component } from 'react';
import { Intent, Button } from "@blueprintjs/core";
import set from 'lodash/set'

export default class CourseEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.course;
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState(
            set(this.state, e.target.name, e.target.value), 
            () => this.props.onChange(this.state)
        );
    }

    render() {
        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="pt-dialog-body">
                    <div className="input-row row">
                        <input className="col-xs-4 pt-input pt-fill" type="text" placeholder="Vak afkorting" name="shortName" dir="auto" onChange={this.onChange} value={this.state.shortName} autoFocus />
                        <input className="col-xs-8 pt-input pt-fill" type="text" placeholder="Vaknaam" name="name" dir="auto" onChange={this.onChange} value={this.state.name} />
                    </div>
                </div>
                <div className="pt-dialog-footer">
                    <div className="pt-dialog-footer-actions">
                        <Button
                            intent={Intent.SUCCESS}
                            loading={this.props.isSaving}
                            type="submit"
                            text="Opslaan"
                        />
                    </div>
                </div>
            </form>
        )
    }
}
