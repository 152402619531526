import React, { Component } from 'react';
import AuthenticateForm from './AuthenticateForm';
import { Button } from '@blueprintjs/core';

class Authenticating extends Component {
  render() {
    return (
      <AuthenticateForm onSubmit={this.props.onSubmit}>
          <h4>Authenticeren...</h4>
          <p className="authentication-description">We hebben een bevestigingsmail naar “{this.props.email}” gestuurd.</p>
          <Button className="pt-button" type="submit" loading={this.props.loading}>Opnieuw versturen</Button>
      </AuthenticateForm>
    );
  }
}

export default Authenticating;
