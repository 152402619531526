import React from "react";
import { connect } from "react-redux";
import { getSchoolById, isLoading } from "../../store/School/selectors";
import { withRouter } from "react-router";
import {
  Tooltip,
  Position,
  Card,
  NonIdealState,
  Spinner,
  Icon,
} from "@blueprintjs/core";
import { loginAsSchool } from "../../store/AuthenticatedUser/actions";
import Avatar from "../../components/Avatar";
import { fetchSchoolsIfNeeded } from "../../store/School/actions";
import defaultTo from 'lodash/defaultTo'
import { usersBySchool } from "../../store/User/selectors";

const Navbar = ({ history, match, school, loginAsSchool }) => (
  <nav className="pt-navbar row middle-xs">
    <div className="pt-navbar-group col-xs">
      <button
        className="pt-button pt-icon-arrow-left pt-large show-up-to-desktop"
        onClick={() => history.goBack()}
      />
      <h3 className="pt-navbar-heading">{school.name}</h3>
    </div>
    <div className="pt-navbar-group pt-align-right pt-navbar--button-group">
      <Tooltip content="Login als school" position={Position.BOTTOM}>
        <button
          className="pt-button pt-icon-key pt-large"
          onClick={() => loginAsSchool(school.id)}
        />
      </Tooltip>
      <Tooltip content="Bewerken" position={Position.BOTTOM_RIGHT}>
        <button
          className="pt-button pt-intent-success pt-icon-edit pt-large"
          onClick={() => history.push(`${match.url}/edit`)}
        />
      </Tooltip>
    </div>
  </nav>
);

const SchoolDataCard = ({ school }) => (
    <div className="row">
      <div className="col-xs" style={{margin: '20px 25px'}}>
        <h5>Schoolgegevens</h5>
        <Card>
            <p className="body-2">
                <strong>{school.name}</strong>
            </p>
            <address className="body-1">
                {school.address.street} {school.address.streetnumber}
                <br />
                {school.address.zip} {school.address.city}
            </address>
            <p className="body-2">Verdienste tutoren per bijles:</p>
            <p className="body-1">
                {(defaultTo(school.tutorFee, 700) / 100).toLocaleString('nl-NL', { currency: 'EUR', style: 'currency' })}
            </p>
        </Card>
      </div>
    </div>
)

const SchoolUserList = ({ users, history }) => (
    <div className="row">
      <div className="col-xs">
        <h5 style={{margin: '0 25px'}}>
          Gebruikers{" "}
          <span className="pt-tag label orange">
            {users.length}
          </span>
        </h5>
        <div className="">
          <div className="list">
            {users.map(user => (
              <div key={user.id} className="list-item row middle-xs" onClick={() => history.push(`/users/${user.id}`)}>
                <Avatar src={user.avatarUrl} placeholder={user.name} />
                <div className="two-line col-xs">      
                  <p className="body-2">{user.name}</p>
                  <p className="body-1">{user.email}</p>
                </div>
                <div className="col-xs">
                  {user.deletedAt !== null && <Icon iconName="trash" />}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
)

class SchoolDetailContainer extends React.Component {
    componentDidMount() {
        this.props.fetchSchool()
    }

    render() {
        const props = this.props

        if (props.isLoading || !props.school) {
            return (<NonIdealState visual={<Spinner />}/>)
        }

        return (
            <React.Fragment>
                <Navbar {...props} />
                <SchoolDataCard {...props} />
                <SchoolUserList {...props} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    school: getSchoolById(state, ownProps.match.params.id),
    users: usersBySchool(state, ownProps.match.params.id),
    isLoading: isLoading(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginAsSchool: school => {
      dispatch(loginAsSchool(school));
      ownProps.history.push(`/schools`);
    },
    fetchSchool: () => dispatch(fetchSchoolsIfNeeded())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SchoolDetailContainer)
);
