import React, { Component } from "react";
import { Popover, Icon, Position, Tooltip } from "@blueprintjs/core";
import random from 'lodash/random'
import emojis from './Emoijs'

const SignupBubble = ({ top, left, name, avatar }) => {
  return (
    <div className="sign-up-bubble" style={{top: `${top}%`, left: `${left}%`}}>
      <Tooltip content={name} isOpen={true} inline={true}>
        {avatar 
          ? <img className="sign-up-bubble-name" alt="user avatar" src={avatar} />
          : <img className="sign-up-bubble-name" alt="user emoij" src={emojis[random(0, emojis.length-1)]} />}
      </Tooltip>
    </div>
  )
}

const positions = [
  { top: 10, left: 5},
  { top: 22, left: 81},
  { top: 73, left: 52},
  { top: 54, left: 68},
  { top: 40, left: 41},
  { top: 62, left: 33},
  { top: 12, left: 19},
  { top: 65, left: 10},
  { top: 10, left: 63},
  { top: 40, left: 5},
  { top: 64, left: 87},
]

class SchoolCode extends Component {
  render() {
    return (
      <div className="schoolcode-content-wrapper">
        <div className="sign-up-container">
          {this.props.signups.map((user, i) => {
            const position = positions[i]
            return <SignupBubble key={i} {...position} name={user.name} avatar={user.avatarUrl} />
          })}
        </div>
        <div className="schoolcode-content">
          <p>
            Geef deze code aan je leerlingen zodat ze zich kunnen aanmelden. De
            code is 24 uur geldig.
          </p>
          <div className="generated-schoolcode-wrapper">
            <h2 className="generated-schoolcode">{this.props.code.code}</h2>
            <Popover
              className="schoolcode-help"
              position={Position.RIGHT_TOP}
              content={
                <div className="schoolcode-explain">
                  <h4>Schoolcode help</h4>
                  <p>
                    Leerlingen kunnen de schoolcode invullen direct na het
                    aanmelden. Als een leerling al is aangemeld bij een school
                    zal de leerling zich eerst moeten afmelden via de
                    instellingen.
                  </p>
                </div>
              }
              target={<Icon iconName="pt-icon-help" />}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolCode;
