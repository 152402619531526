import React from 'react';
import { Button,
         Intent,
         NonIdealState,
         Spinner } from "@blueprintjs/core";
import SchoolListItem from './SchoolListItem';
import { branch, compose, renderComponent } from 'recompose'

const EmptyState = (props) => (
    <NonIdealState
        title="Geen scholen"
        description="Er maken nog geen scholen gebruik van Savvy Learning. Voeg er een toe!"
        action={
            <Button
                onClick={() => props.history.push(`/schools/create`)}
                intent={Intent.PRIMARY}
                className="pt-large"
                text="School toevoegen" />
        } />
)

const LoadingState = () => (
    <NonIdealState visual={<Spinner />} />
)

const withEmptyState    = branch(({ schools }) => !schools.length, renderComponent(EmptyState))
const withLoadingState  = branch(({ isLoading }) => isLoading, renderComponent(LoadingState))

const SchoolList = ({ schools, loginAsSchool }) => (
    <div className="list">
        {schools.map((school) => (
            <SchoolListItem 
                key={school.id}
                school={school}
                loginAsSchool={loginAsSchool} />
        ))}
    </div>
)

export default compose(
    withLoadingState,
    withEmptyState
)(SchoolList)