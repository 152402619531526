import React, { Component } from "react";
import { sumBy } from 'lodash'
import { NonIdealState } from "@blueprintjs/core";
import BarGroup from './BarGroup'
import Bar from './Bar'

export default class TalentAndWeaknessChart extends Component { 
  render() {
    const { talents, weaknesses } = this.props

    return (
      <div className="graph">
        <div className="graph-inner-wrapper">
          <h5 className="graph-title">
            Talenten &amp; verbeteren
          </h5>
          <p className="graph-explain-content body-2">
            In talenten zie je welk vak het meeste talenten kent en welk vak het moeilijkste wordt bevonden.
          </p>
          <div className="divider"></div>
          <div className="group-graphs row">
            {this.renderTalentGraph(talents)}
            {this.renderWeaknessesGraph(weaknesses)}
          </div>
        </div>
      </div>
    )
  }

  renderTalentGraph(talents) {
    const totalTalents = sumBy(talents, 'talents')
    
    if (!talents || !talents.length) {
      return <NonIdealState description="Geen talenten data beschikbaar" visual="horizontal-bar-chart-desc" />
    }

    return (
      <BarGroup className="col-xs-12 col-md-6" labels={['Vak', 'Talenten']}>
        {talents.map((talent, i) => <Bar key={i} name={talent.course} count={talent.talents} total={totalTalents} />)}
      </BarGroup>
    )
  }
  
  renderWeaknessesGraph(weaknesses) {
    const totalWeaknesses = sumBy(weaknesses, 'weaknesses')
    
    if (!weaknesses || !weaknesses.length) {
      return <NonIdealState description="Geen verbeterpunten data beschikbaar" visual="horizontal-bar-chart-desc" />
    }

    return (
      <BarGroup className="col-xs-12 col-md-6" labels={['Vak', 'Verbeteren']}>
        {weaknesses.map((weakness, i) => <Bar key={i} name={weakness.course} count={weakness.weaknesses} total={totalWeaknesses} />)}
      </BarGroup>
    )
  }
}