const config = {
  apiUrl: () => {
    return config.baseUrl() + "/v1";
  },
  baseUrl: () => {
    switch (window.location.hostname) {
      case "localhost":
      case "127.0.0.1":
        // return 'https://api.dev.getsavvy.nl'
        return "http://localhost:1337";
      case "portal.dev.getsavvy.nl":
        return "https://api.dev.getsavvy.nl";
      case "portal.staging.getsavvy.nl":
        return "https://api.staging.getsavvy.nl";
      case "portal.qa.getsavvy.nl":
        return "https://api.qa.getsavvy.nl";
      default:
        return "https://api.getsavvy.nl";
    }
  }
};

export default config;
