import React, { Component } from 'react';
import logoBlue from './logo-blue.svg';

class ErrorPage extends Component {
  render() {
    return (
      <div className="error-page">
        <img className="logo" src={logoBlue} alt="Savvy Learning logo"/>
        <div className="error-wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ErrorPage;
