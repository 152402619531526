import axios from "axios";
import auth from "./auth";
import Promise from "bluebird";
import token from "./token";
import { Logger } from "../util/logger";
import config from "./config";

const http = axios.create({
  baseURL: config.apiUrl()
});

// Add a request interceptor for accessToken
http.interceptors.request.use(function(config) {
  // Do something before request is sent
  if (auth.accessToken() && auth.accessToken().length) {
    config.headers["Authorization"] = `Bearer ${auth.accessToken()}`;
  }
  return config;
});

const fetchNewAccessToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl()}/auth/refreshToken`, {
        refreshToken: auth.refreshToken()
      })
      .then(res => resolve(res.data.token))
      .catch(err => reject(err));
  });
};

// Add a request interceptor to retrieve new accessToken
http.interceptors.request.use(
  config => {
    return new Promise((resolve, reject) => {
      if (auth.isAuthenticated() && auth.isAccessTokenExpired()) {
        // Fetch new accessToken
        Logger.debug("Fetch new accessToken");
        fetchNewAccessToken()
          .then(_token => {
            token.set("accessToken", _token);
            resolve(config);
          })
          .catch(err => reject(err));
      } else {
        return resolve(config);
      }
    });
  },
  error => {
    return Promise.reject(error);
  }
);

export function resource(path, actions) {
  var obj = {
    find: params => http.get(`${path}`, { params }).then(res => res.data),
    findOne: (id, params) =>
      http.get(`${path}/${id}`, { params }).then(res => res.data),
    update: (id, obj) => http.put(`${path}/${id}`, obj).then(res => res.data),
    create: obj => http.post(`${path}`, obj).then(res => res.data),
    destroy: id => http.delete(`${path}/${id}`).then(res => res.data)
  };
  return Object.assign(obj, actions);
}

export default http;
