import React from 'react';

const SchoolNavbar = ({ openSidenav, history, match, onSearch }) => (
  <nav className="pt-navbar row middle-xs">
      <div className="pt-navbar-group col-xs">
          <button 
            className="pt-button pt-icon-menu pt-large show-up-to-desktop"
            onClick={() => openSidenav()}>
          </button>
          <h3 className="pt-navbar-heading hide-up-to-tablet-portrait">Scholen</h3>
          <input
            className="pt-input pt-fill"
            placeholder="Zoeken tussen scholen..."
            type="text"
            onChange={(e) => onSearch(e)} />
          <button 
            className="pt-button pt-intent-success pt-icon-plus pt-large"
            onClick={() => history.push(match.url + '/create')}>
          </button>
      </div>
  </nav>
)

export default SchoolNavbar