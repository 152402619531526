import * as ActionTypes from "./actions";

const initialState = {
  isFetching: false,
  code: {},
  error: null,
  signups: []
};

const Code = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_SCHOOL_CODE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ActionTypes.CREATE_SCHOOL_CODE_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        code: action.code
      };
    case ActionTypes.CREATE_SCHOOL_CODE_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case ActionTypes.NEW_SIGNUP:
      return {
        ...state,
        signups: state.signups.concat([action.user])
      }
    default:
      return state;
  }
};

export default Code;
