import React, { Component } from 'react';
import { Dialog } from "@blueprintjs/core";
import TeacherEditForm from './TeacherEditForm';

export default class TeacherEditDialog extends Component {

  render() {
    return (
      <Dialog
              isOpen={this.props.isOpen}
              onClose={this.props.toggleDialog}
              title="Nieuwe docent"
          >
          <TeacherEditForm {...this.props} />
      </Dialog>
    )
  }
}
