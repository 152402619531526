import Plan from "./endpoints";

export const PLANS_REQUEST = "PLANS_REQUEST";
export const PLANS_SUCCESS = "PLANS_SUCCESS";
export const PLANS_FAILURE = "PLANS_FAILURE";

const plansRequest = () => ({
  type: PLANS_REQUEST
});
const plansSucccess = plans => ({
  type: PLANS_SUCCESS,
  plans
});
const plansFailure = error => ({
  type: PLANS_FAILURE,
  error
});

const fetchPlans = () => dispatch => {
  dispatch(plansRequest());
  return Plan.find()
    .then(plans => dispatch(plansSucccess(plans)))
    .catch(error => dispatch(plansFailure(error)));
};

const shouldFetchPlans = state => {
  const plans = state.plans;
  if (!plans.plans) {
    return true;
  }
  if (plans.isFetching) {
    return false;
  }
  return true;
};

export const fetchPlansIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchPlans(getState())) {
    return dispatch(fetchPlans());
  }
};
