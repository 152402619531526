import * as ActionTypes from './actions'
import unionBy from 'lodash/unionBy'
import * as Selectors from './selectors'

const initialState = {
    isFetching: false,
    isSaving: false,
    error: null,
    isOpen: false,
    levels: [],
    level: null,
    query: '',
    isRemoveLevelAlertOpen: false,
}

export const Level = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.TOGGLE_DIALOG:
            return {
                ...state,
                isOpen: !state.isOpen
            }
        case ActionTypes.LEVEL_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case ActionTypes.LEVEL_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                levels: action.levels
            }
        case ActionTypes.LEVEL_REQUEST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case ActionTypes.SET_LEVEL:
            return {
                ...state,
                level: action.level
            }
        case ActionTypes.SAVE_LEVEL_REQUEST:
            return {
                ...state,
                isSaving: true,
                error: null
            }
        case ActionTypes.SAVE_LEVEL_REQUEST_SUCCESS:
            return {
                ...state,
                level: null,
                isOpen: !state.isOpen,
                isSaving: false,
                levels: unionBy([action.level], state.levels, 'id')
            }
        case ActionTypes.SAVE_LEVEL_REQUEST_FAILURE:
            return {
                ...state,
                isSaving: false,
                error: action.error
            }
        case ActionTypes.TOGGLE_REMOVE_LEVEL_ALERT:
            return {
                ...state,
                isRemoveLevelAlertOpen: !state.isRemoveLevelAlertOpen
            }
        case ActionTypes.DELETE_LEVEL_REQUEST:
            return {
                ...state,
                error: null,
                isRemoving: true
            }
        case ActionTypes.DELETE_LEVEL_REQUEST_SUCCESS:
            return {
                ...state,
                isRemoving: false,
                levels: state.levels.filter(level => level.id !== action.level.id),
                isRemoveLevelAlertOpen: false,
                level: null
            }
        case ActionTypes.DELETE_LEVEL_REQUEST_FAILURE:
            return {
                ...state,
                isRemoving: false,
                error: action.error
            }
        case ActionTypes.SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        default:
            return state;
    }
}

export const getVisibleLevels = (state, query) => Selectors.getVisibleLevels(state.Level.levels, query)

export default Level
