import App from "./App/reducers";
import schools from "./School/reducers";
import plans from "./Plan/reducers";
import AuthenticatedUser from "./AuthenticatedUser/reducers";
import Code from "./Code/reducers";
import User from "./User/reducers";
import Course from "./Course/reducers";
import Teacher from "./Teacher/reducers";
import Level from "./Level/reducers";
import PasswordReset from './PasswordReset/reducers';
import Tutor from './Tutor';
import Payment from './Payment';
import Stats from './Stats';
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  App,
  schools,
  plans,
  AuthenticatedUser,
  Code,
  User,
  Course,
  Teacher,
  Level,
  PasswordReset,
  Tutor,
  Payment,
  Stats,
});

export default rootReducer;
