import * as ActionTypes from "./actions";

const initialState = {
  loading: false,
  password: '',
  passwordRepeat: '',
  token: '',
  passwordDidReset: false,
  error: null
};

const PasswordReset = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PASSWORD:
            return {
                ...state,
                password: action.password
            }
        case ActionTypes.SET_PASSWORD_REPEAT:
            return {
                ...state,
                passwordRepeat: action.passwordRepeat
            }
        case ActionTypes.SET_TOKEN:
            return {
                ...state,
                token: action.token
            }
        case ActionTypes.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case ActionTypes.RESET_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                password: '',
                passwordRepeat: '',
                token: '',
                passwordDidReset: true,
            }
        case ActionTypes.RESET_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                password: '',
                passwordRepeat: '',
                error: action.error
            }
    default:
      return state;
  }
};

export default PasswordReset;
