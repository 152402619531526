import React, { Component } from 'react';

export default class TutorsNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = { query: this.props.query };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState(
      { query: e.target.value },
      () => this.props.onSearch(this.state.query)
    );
  }

  render() {
    return (
      <nav className="pt-navbar row middle-xs">
        <div className="pt-navbar-group col-xs">
          <button className="pt-button pt-icon-menu pt-large show-up-to-desktop" onClick={() => this.props.openSidenav()}></button>
          <h3 className="pt-navbar-heading hide-up-to-tablet-portrait">Bijles</h3>
          <input className="pt-input pt-fill" placeholder="Zoek op naam..." type="text" name="query" value={this.state.query} onChange={this.onChange}/>
        </div>
      </nav>
    )
  }
}
