import React, { Component } from "react";
import { Card } from "@blueprintjs/core";

export default class StatsCard extends Component {
  render() {
    return (
      <div className="card-wrapper card-wrapper-stats">
        <Card>
          <div className="card-content graph">
            <div className="card-text">
              <h1 className="header-one">{this.props.value}</h1>
              <p className="graph-explain-content body-2">{this.props.description}</p>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}