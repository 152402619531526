import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import "normalize.css";
import "@blueprintjs/core/dist/blueprint.css";
import "flexboxgrid";
import { Provider } from "react-redux";
import Routes from "./routes";
import configureStore from "./store";
import { loadState, saveState } from "./util/localStorage";
import throttle from "lodash/throttle";

const persistedState = loadState();
export const store = configureStore(persistedState);

const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <Routes />
    </Router>
  </Provider>
);

store.subscribe(
  throttle(() => {
    const state = { AuthenticatedUser: store.getState().AuthenticatedUser }
    state.AuthenticatedUser.isAuthenticating = false
    state.AuthenticatedUser.loading = false
    saveState(state);
  }, 1000)
);

ReactDOM.render(<Root store={store} />, document.getElementById("root"));
registerServiceWorker();
