import React, { Component } from 'react';
import ErrorPage from './index';
import { Button } from '@blueprintjs/core';

class Unauthorized extends Component {
  render() {
    return (
      <ErrorPage>
          <h1>403</h1>
          <p className="error-description">Je hebt geen toegang tot deze pagina</p>
          <Button className="pt-button" type="submit" onClick={this.props.history.goBack}>Terug</Button>
      </ErrorPage>
    );
  }
}

export default Unauthorized;
