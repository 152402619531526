import React, { Component } from 'react';

export default class CourseNavbar extends Component {
  render() {
    return (
      <nav className="pt-navbar row middle-xs">
        <div className="pt-navbar-group col-xs">
          <button  className="pt-button pt-icon-menu pt-large show-up-to-desktop" onClick={() => this.props.openSidenav()}></button>
          <h3 className="pt-navbar-heading hide-up-to-tablet-portrait">Vakken</h3>
          <input className="pt-input pt-fill" placeholder="Zoek tussen vakken..." type="text" onChange={this.props.onSearch} />
          <button onClick={this.props.onCreate} className="pt-button pt-intent-success pt-icon-plus pt-large"></button>
        </div>
      </nav>
    )
  }
}
