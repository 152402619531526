import React, { Component } from 'react';
import { Intent, Button } from "@blueprintjs/core";
import set from 'lodash/set'

export default class TeacherEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.teacher;
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState(
            set(this.state, e.target.name, e.target.value),
            () => this.props.onChange(this.state)
        );
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.onSubmit(e)
    }

    render() {
        return (
            <form onSubmit={this.onSubmit}>
                <div className="pt-dialog-body">
                    <input className="pt-input pt-fill" type="text" name="name" placeholder="Naam" value={this.state.name} onChange={this.onChange} dir="auto" autoFocus />
                    <input className="pt-input pt-fill" type="email" name="email" placeholder="E-mailadres" value={this.state.email} onChange={this.onChange} dir="auto" />
                    {/*<div className="pt-select pt-fill">
                      <select>
                        <option disabled selected>Kies een rol</option>
                        {this.props.roles.filter(role => role.name !== 'Admin' || role.name !== 'Student').map(role => (<option key={role.id} value={role.id}>{role.name}</option>))}
                      </select>
                    </div>*/}
                </div>
                <div className="pt-dialog-footer">
                    <div className="pt-dialog-footer-actions">
                        <Button
                            type="submit"
                            intent={Intent.SUCCESS}
                            loading={this.props.isSaving}
                            text="Opslaan"
                        />
                    </div>
                </div>
            </form>
        )
    }
}
