import React, { Component } from "react";
import { Line } from 'react-chartjs-2';
import reduce from 'lodash/reduce'
import defaults from 'lodash/defaults'

const chartOptions = { 
  elements: {
    line: {
      tension: .25
    },
    point: {
      radius: 0
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      boxWidth: 12,
      padding: 20, 
    }
  },
  title: {
    display: false,
  },
  layout: {
    // padding: 25
  },
  scales: {
    yAxes: [{
      position: 'left',
      ticks: {
        padding: 10,
        beginAtZero: true,
        maxTicksLimit: 5,
        fontFamily: 'futura-pt',
        fontColor: '#000',
        fontSize: 12,
        callback: function (value, index, values) {
          if (Math.floor(value) === value) {
            return value;
          }
        }
      },
      gridLines: {
        drawBorder: false,
        color: '#E0E0E0',
        borderDash: [3, 3],
        zeroLineColor: '#E0E0E0',
        tickMarkLength: 0,
      }
    }],
    xAxes: [{
      barThickness: 15,
      gridLines: {
        drawBorder: false,
        color: '#E0E0E0',
        borderDash: [3, 3],
        zeroLineColor: '#E0E0E0',
        tickMarkLength: 10,
      },
      ticks: {
        fontFamily: 'futura-pt',
        fontColor: '#000',
        fontSize: 12
      },
      afterTickToLabelConversion: function (data) {
        var xLabels = data.ticks;
        xLabels.forEach(function (labels, i) {
          if (i % 2 === 1) {
            xLabels[i] = '';
          }
        });
      }
    }]
  },
  tooltips: {
    mode: 'index',
    intersect: false,
    displayColors: true,
    yAlign: 'bottom',
    xAlign: 'center',
    titleFontFamily: 'futura-pt',
    bodyFontFamily: 'futura-pt',
  }
}

const colors = [
  "#00BFFF",
  "#9800FF",
  "#FF0707"
]

export default class StatsLineChart extends Component {
  data(canvas) {
    return {
      labels: this.props.labels,
      datasets: reduce(this.props.datasets, (acc, dataset, index) => {
        dataset = defaults(dataset, {
          fill: false,
          borderColor: colors[index],
          backgroundColor: colors[index],
          borderWidth: 0,
          pointBackgroundColor: colors[index],
          pointRadius: 4,
        })

        acc.push(dataset)

        return acc;
      }, [])
    }
  }
  
  render() {
    return (
      <div className="graph">
        <div className="graph-inner-wrapper">
          <h5 className="graph-title">
            <span>{this.props.title}</span>
          </h5>
          <p className="graph-explain-content body-2">
            {this.props.explainContent}
          </p>
          <div className="divider"></div>
          <div className="graph-inner-wrapper-chart-js">
            <Line data={(canvas) => this.data(canvas)}
              width={100}
              height={60}
              options={chartOptions}
            />
          </div>
        </div>
      </div>
    );
  }
}
