import emoji1 from './elf_1f9dd.png';
import emoji2 from './female-cook_1f469-200d-1f373-2.png'
import emoji3 from './female-judge_1f469-200d-2696-fe0f-2.png'
import emoji4 from './female-office-worker_1f469-200d-1f4bc.png'
import emoji5 from './female-pilot_1f469-200d-2708-fe0f.png'
import emoji6 from './female-police-officer_1f46e-200d-2640-fe0f.png'
import emoji7 from './female-singer_1f469-200d-1f3a4.png'
import emoji8 from './female-sleuth_1f575-fe0f-200d-2640-fe0f-2.png'
import emoji9 from './male-artist_1f468-200d-1f3a8.png'
import emoji10 from './male-astronaut_1f468-200d-1f680.png'
import emoji11 from './male-farmer_1f468-200d-1f33e-2.png'
import emoji12 from './male-guard_1f482-200d-2642-fe0f.png'
import emoji13 from './male-health-worker_1f468-200d-2695-fe0f-2.png'
import emoji14 from './male-mechanic_1f468-200d-1f527.png'
import emoji15 from './male-scientist_1f468-200d-1f52c-2.png'
import emoji16 from './male-teacher_1f468-200d-1f3eb-2.png'
import emoji17 from './man-mage_1f9d9-200d-2642-fe0f.png'
import emoji18 from './man-superhero_1f9b8-200d-2642-fe0f.png'
import emoji19 from './woman-supervillain_1f9b9-200d-2640-fe0f.png'
import emoji20 from './woman-vampire_1f9db-200d-2640-fe0f.png'
import emoji21 from './zombie_1f9df.png'

export default [
    emoji1, emoji2, emoji3, emoji4, emoji5, emoji5, emoji6, emoji7, emoji8, emoji9, emoji10, emoji11, emoji12, emoji13, emoji14, emoji15, emoji16, emoji17, emoji18, emoji19, emoji20, emoji21,
]