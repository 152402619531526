import unionBy from 'lodash/unionBy';
import orderBy from 'lodash/orderBy';

const initialState = {
  isFetching: false,
  payments: [],
  requestErr: null
};

export const Payment = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_REQUEST:
      return { ...state, isFetching: true, requestErr: null };
    case FETCH_PAYMENT_REQUEST_SUCCESS:
      return { ...state, isFetching: false, payments: unionBy(action.payments, state.payments, 'id') };
    case FETCH_PAYMENT_REQUEST_FAILURE:
      return { ...state, isFetching: false, requestErr: action.err };
    default:
      return state;
  }
};
export default Payment;

// selectors
export const selectPayments = state => orderBy(state.payments, ['createdAt', 'updatedAt'], ['desc', 'desc']);

// actions
export const FETCH_PAYMENT_REQUEST = 'FETCH_PAYMENT_REQUEST';
export const FETCH_PAYMENT_REQUEST_SUCCESS = 'FETCH_PAYMENT_REQUEST_SUCCESS';
export const FETCH_PAYMENT_REQUEST_FAILURE = 'FETCH_PAYMENT_REQUEST_FAILURE';

// action creators
const paymentRequest            = () => ({ type: FETCH_PAYMENT_REQUEST })
const paymentRequestSuccess     = payments => ({ payments, type: FETCH_PAYMENT_REQUEST_SUCCESS });
const paymentRequestFailure     = err => ({ err, type: FETCH_PAYMENT_REQUEST_FAILURE });

export const fetchPayments = () =>  (dispatch, getState, { http }) => {
  dispatch(paymentRequest());
  http.get(`/admin/payment`)
    .then(res => res.data)
    .then(payments => dispatch(paymentRequestSuccess(payments)))
    .catch(err => dispatch(paymentRequestFailure(err)))
}

export const payoutPayment = payment => (dispatch, getState, { http }) => {
  dispatch(paymentRequest());
  http.put(`/admin/payment/${payment.id}`, { payoutStatus: 'processed' })
    .then(res => res.data)
    .then(payment => dispatch(paymentRequestSuccess([payment])))
    .catch(err => dispatch(paymentRequestFailure(err)))
}