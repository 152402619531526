export const userById = (state, id) => {
    const users = state.User.users
    const user = users.filter(user => {
        return user.id === parseInt(id, 10)
    }).pop()
    return user
}

export const usersBySchool = (state, school) => {
    const users = state.User.users
    return users.filter(user => user.school === school || user.school === parseInt(school,10))
}