import React, { Component } from "react";
import { Intent, Button, NumericInput } from "@blueprintjs/core";
import { Formik, Field } from "formik";

export default class SchoolDetailForm extends Component {
  onSubmit(values, actions) {
    this.props.onSubmit(values)
  }
  
  render() {
    return (
      <Formik 
        initialValues={this.props.school}
        onSubmit={this.onSubmit.bind(this)}
        render={props => (
          <form onSubmit={props.handleSubmit} className="form">
            <p className="body-2">Schoolgegevens</p>
            <Field className="pt-input pt-fill"
              placeholder="Schoolnaam"
              type="text"
              name="name" />
            <Field className="pt-input pt-fill"
              placeholder="Straatnaam"
              type="text"
              name="address.street" />
            <Field className="pt-input pt-fill"
              placeholder="Huisnummer"
              type="text"
              name="address.streetnumber" />
            <Field className="pt-input pt-fill"
              placeholder="Postcode"
              type="text"
              name="address.zip" />
            <Field className="pt-input pt-fill"
              placeholder="Plaats"
              type="text"
              name="address.city" />
            <p className="body-2">Verdienste tutoren per bijles</p>
            <NumericInput
              leftIconName="euro"
              large="true"
              className="pt-fill"
              name="tutorFee"
              min={3.50}
              max={25}
              value={props.values.tutorFee / 100}
              onValueChange={(number) => props.setFieldValue('tutorFee', number * 100)}
            />
            <div className="pt-dialog-footer-actions">
              <Button
                type="submit"
                intent={Intent.SUCCESS}
                loading={this.props.isSaving}
                text="Opslaan"
              />
            </div>
          </form>  
        )}
      />
    );
  }
}
