import React, { Component } from 'react';
import AuthenticateForm from '../Authenticate/AuthenticateForm';
import { Button, Callout, Intent } from '@blueprintjs/core';
import set from 'lodash/set';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: this.props.newPassword || '',
      passwordRepeat: this.props.passwordRepeat || '',
    }
  }

  onChange(e) {
    this.setState(set(this.state, e.target.name, e.target.value), () => this.props.onChange(this.state))
  }

  render() {
    return (
      <AuthenticateForm onSubmit={this.props.onSubmit}>
          <h4>Wachtwoord instellen</h4>
          {this.props.error && (
            <Callout intent={Intent.DANGER} title="Oeps!">
              Er is iets fout gegaan: {this.props.error.code} - {this.props.error.message}
            </Callout>
          )}

          {this.props.passwordDidReset ? (
            <Callout title="Gelukt!" intent={Intent.SUCCESS}>
              Je wachtwoord is gewijzigd
            </Callout>
          ) : (
            <div className="authentication-form">
              <p className="authentication-description">Verzin een nieuw wachtwoord</p>
              <div className="authentication-input-group">
                <input type="password" className="pt-input" placeholder="Nieuw wachtwoord" name="newPassword" value={this.state.newPassword} onChange={(e) => this.onChange(e)} required />
                <input type="password" className="pt-input" placeholder="Wachtwoord herhalen" name="passwordRepeat" value={this.state.passwordRepeat} onChange={(e) => this.onChange(e)} required />
              </div>
              <Button className="pt-intent-primary" loading={this.props.loading} type="submit">Opslaan</Button>
            </div>
          )}

      </AuthenticateForm>
    );
  }
}

export default ForgotPasswordForm;
