import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import intersection from 'lodash/intersection';

const hasRole = (roles, rolesToCheck) => !!intersection(roles, rolesToCheck).length

export const PrivateRouteComponent = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isAuthenticated === true && hasRole(rest.roles, rest.hasRole) ? (
        <Component {...props} />
      ) : rest.isAuthenticated === true && !hasRole(rest.roles, rest.hasRole) ? (
        <Redirect
          to={{
            pathname: "/403",
            state: { from: props.location }
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: "/authenticate",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const PrivateRoute = connect((state) => ({
  isAuthenticated: state.AuthenticatedUser.isAuthenticated,
  roles: state.AuthenticatedUser.roles
}))(PrivateRouteComponent)

export default PrivateRoute