export const TOGGLE_SIDENAV = "TOGGLE_SIDENAV";
export const toggleSidenav = () => ({
    type: TOGGLE_SIDENAV
})

export const DISABLE_SIDENAV = "DISABLE_SIDENAV";
export const disableSidenav = () => ({
    type: DISABLE_SIDENAV
})

export const ENABLE_SIDENAV = "ENABLE_SIDENAV";
export const enableSidenav = () => ({
    type: ENABLE_SIDENAV
})

export const OPEN_SIDENAV = "OPEN_SIDENAV";
export const openSidenav = () => ({
    type: OPEN_SIDENAV
})

export const CLOSE_SIDENAV = "CLOSE_SIDENAV";
export const closeSidenav = () => ({
    type: CLOSE_SIDENAV
})

export const DISABLE_LOGOUT = `DISABLE_LOGOUT`
export const disableLogout = () => ({
    type: DISABLE_LOGOUT
})