import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
import flatMap from 'lodash/flatMap'
import includes from 'lodash/includes'

const initialState = {
  levels: [],
  years: [],
  schoolYears: [],
  filter: {
    start: (moment().month() >= 9)
      ? moment().month(9).startOf('month').unix()
      : moment().subtract(1, 'year').month(9).startOf('month').unix(),
    end: moment().unix()
  },  
};

const reducerName = `stats`
const createActionName = (name) => `${reducerName}/${name}`

export const Stats = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEVELS:
      return { ...state, levels: action.levels }
    case SET_YEARS:
      return { ...state, years: action.years }
    case SET_SCHOOL_YEARS:
      return { ...state, schoolYears: action.schoolYears }
    case SET_FILTER:
      return { ...state, filter: action.filter }
    default:
      return state
  }
};
export default Stats;

// selectors
export const getLevels = state => {
  return state.Stats.levels.map(level => {
    level.checked = includes(state.Stats.filter.levels, level.id)
    return level
  })
}
export const getYears = state => {
  return state.Stats.years.map(year => {
    year.checked = includes(state.Stats.filter.years, year.year)
    return year
  })
}
export const getSchoolYears = state => {
  const { start, end } = state.Stats.filter
  return state.Stats.schoolYears.map(year => {
    year.selected = (start === year.start && end === year.end)
    return year
  })
}
export const getFilterQuery = state => {
  const { start, end , ...filter } = state.Stats.filter
  return { start, end, filter }
}

// actions
export const SET_LEVELS = createActionName(`SET_LEVELS`)
export const SET_YEARS = createActionName(`SET_YEARS`)
export const SET_SCHOOL_YEARS = createActionName(`SET_SCHOOL_YEARS`)
export const SET_FILTER = createActionName(`SET_FILTER`)

// action creators
export const setLevels = payload => (dispatch, getState) => {
  const levels = payload.levels
  const years = uniqBy(flatMap(levels, 'years'), 'year')

  if (!getLevels(getState()).length) {
    dispatch(setFilter({
      years: years.map(y => y.year),
      levels: levels.map(l => l.id)
    }))
  }

  dispatch({ type: SET_LEVELS, levels })
  dispatch({ type: SET_YEARS, years })
}
export const calculateSchoolYears = payload => (dispatch) => {
  const { school } = payload

  const start = moment(school.createdAt).startOf('month')
  const schoolYears = []

  if (start.month() < 8) {
    start.subtract(1, 'year')
  }

  while ( start.isBefore(moment()) ) {
    const startSchoolYear = moment(start).month(8)
    const endSchoolYear = moment(startSchoolYear).add(11, 'months').endOf('month')

    schoolYears.push({
      start: startSchoolYear.unix(),
      end: endSchoolYear.unix(),
      name: `${startSchoolYear.year()}-${endSchoolYear.year()}`
    })

    if (endSchoolYear.isAfter(moment())) {
      break;
    }
    start.add(1, 'year')
  }

  dispatch(setSchoolYears(schoolYears))
}
export const setSchoolYears = (schoolYears) => ({
  type: SET_SCHOOL_YEARS,
  schoolYears
})
export const setFilter = filter => (dispatch, getState) => {
  // merge with already existing filter
  filter = Object.assign({}, getState().Stats.filter, filter)
  
  if (filter.year) {
    const years = getYears(getState())
    const newYears = years.map(year => {
      if (year.year === filter.year.year) {
        year.checked = !year.checked
      }
      return year
    }).filter(y => y.checked).map(y => y.year)
    filter.years = newYears
    delete filter.year
  }

  if (filter.level) {
    const levels = getLevels(getState())
    const newLevels = levels.map(level => {
      if (level.id === filter.level.id) {
        level.checked = !level.checked
      }
      return level
    }).filter(l => l.checked).map(l => l.id)
    filter.levels = newLevels
    delete filter.level
  }

  // manipulate filter somehow
  dispatch({
    type: SET_FILTER,
    filter
  })
}
export const resetFilter = () => (dispatch, getState) => {
  const filter = initialState.filter
  const levels = getState().Stats.levels
  filter.levels = levels.map(l => l.id)
  filter.years = uniqBy(flatMap(levels, 'years'), 'year').map(y => y.year)

  dispatch({
    type: SET_FILTER,
    filter
  })
}