import React from 'react';
import {
    connect
} from 'react-redux';
import LevelsEditForm from '../../components/Levels/LevelsEditForm';
import { getLevel, isFetching, isSaving } from '../../store/Level/selectors';
import { fetchLevels, saveForm } from '../../store/Level/actions';
import { Spinner, NonIdealState } from '@blueprintjs/core';

const mapStateToProps = (state, { match: { params } }) => ({
    level: getLevel(state, params.id),
    isFetching: isFetching(state),
    isSaving: isSaving(state),
})

const mapDispatchToProps = (dispatch) => ({
    fetchLevels: () => dispatch(fetchLevels()),
    onSubmit: form => dispatch(saveForm(form)),
})

export class LevelEditContainer extends React.Component {
    componentDidMount() {
        if (!this.props.level) {
            this.props.fetchLevels()
        }
    }

    render() {
        if (this.props.isFetching) {
            return <NonIdealState visual={<Spinner />} />
        }
        return <LevelsEditForm {...this.props} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LevelEditContainer)