import React from 'react'
import LevelNavbarContainer from '../../containers/Levels/Navbar';
import LevelEditContainer from '../../containers/Levels/Edit';

export default (props) => (
    <div>
        <LevelNavbarContainer
            {...props}
            title="Leerniveau bewerken"
            hasSearch={false}
            hasActions={false}
        />
        <LevelEditContainer {...props} />
    </div>
)