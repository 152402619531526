import React, { Component } from "react";
import { } from '@blueprintjs/core';

export default class PercentageFiller extends Component {
  

  render() {
    return (
      <div>
        <div className="percentage" style={{ width: `${this.props.percentage}%` }} ></div>
        <div className="percentage-background"></div>
      </div>
    )
  }
}