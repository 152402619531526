import React, { Component } from 'react';
import { Popover, MenuItem, Position, Menu, Button, Intent } from "@blueprintjs/core";
import { NavLink } from 'react-router-dom'

export default class LevelsListItem extends Component {
  render() {
    return (
      <div className="list-item nav-link-list-item row middle-xs">
        <NavLink to={`/levels/${this.props.level.id}/edit`} className="nav-link col-xs row">
          <p className="col-xs body-2">{this.props.level.name}</p>
          <p className="col-xs hide-up-to-tablet-portrait">{`${this.props.level.years.length} leerjaren`}</p>
          <p className="col-xs hide-up-to-tablet-portrait">{`${this.props.level.students.length} studenten`}</p>
        </NavLink>
        <Popover className="col-xs list-item-action" position={Position.LEFT_TOP} content={<Menu>
              {/* <NavLink to={`/levels/${this.props.level.id}`} className="nav-link">
                <MenuItem text="Bekijken"
                          label={<span className="pt-icon-standard pt-icon-chevron-right" />}
                />
              </NavLink> */}
              <NavLink to={`/levels/${this.props.level.id}/edit`} className="nav-link">
                <MenuItem text="Aanpassen" />
              </NavLink>
              <MenuItem
                  intent={Intent.DANGER}
                  onClick={() => this.props.toggleRemoveLevelAlert(this.props.level)}
                  text="Verwijderen"
              />
          </Menu>} target={<Button iconName="more" />} />
        </div>
    )
  }
}
