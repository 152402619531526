import React from 'react';
import connect from './connect';
import { withRouter } from 'react-router-dom';

import TutorNavbar from '../../components/Tutors/TutorNavbar';
import TutorCards from '../../components/Tutors/TutorCards';

import {
  Toaster,
  Position,
  Intent
} from "@blueprintjs/core";

export class TutorContainer extends React.Component {
  componentDidMount() {
    this.props.fetchTutorRequests();
    this.props.fetchTutors();
  }

  componentDidUpdate() {
    const { hasError } = this.props;
    if (!hasError) return null;
    
    let message = '';
      
    switch(hasError.message) {
      case "Request failed with status code 404":
        message = 'Dit verzoek bestaat niet meer'
        break;
      default:
        message = 'Er ging iets fout probeer het opnieuw'
        break;
    }

    this.toaster.show({
      intent: Intent.DANGER,
      message: message,
      iconName: 'warning'
    })
  }

  render() {
    return (
      <div>
        <TutorNavbar {...this.props} />
        <TutorCards {...this.props} />
        <Toaster position={Position.TOP_RIGHT} ref={ref => this.toaster = ref} />
      </div>
    );
  }
}

export default withRouter(connect(TutorContainer))
