import env from './env';

export const Logger = {
    _isDev: () => (env !== 'staging' || env !== 'production'),
    log: (...params) => {
        if (Logger._isDev()) {
            console.log(...params);
        }
    },
    error: (...params) => {
        if (Logger._isDev()) {
            console.error(...params);
        }
    },
    debug: (...params) => {
        if (Logger._isDev()) {
            console.debug(...params);
        }
    },
};